/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthModel, MessageModel, StoreMessageModel, UserModel } from "./_models";

const AUTH_LOCAL_STORAGE_KEY = "token";
const USER_DATA_LOCAL_STORAGE_KEY = "user-data";
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue || lsValue === "undefined") {
    return;
  }

  const accessValue = lsValue;

  try {
    //TODO IMPLEMENT STORING AND RETRIEVING REFRESH TOKEN
    const auth: AuthModel = { key: accessValue };
    if (auth) {
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const getUser = (): UserModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(
    USER_DATA_LOCAL_STORAGE_KEY
  );
  if (!lsValue || lsValue === "undefined") {
    return;
  }

  const userValue = JSON.parse(lsValue);

  try {
    //TODO IMPLEMENT STORING AND RETRIEVING REFRESH TOKEN
    const user: UserModel = userValue as UserModel;
    if (user) {
      return user;
    }
  } catch (error) {
    console.error("USER LOCAL STORAGE PARSE ERROR", error);
  }
};

const setUser = (userData: UserModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(userData);
    localStorage.setItem(USER_DATA_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("USER DATA LOCAL STORAGE SAVE ERROR", error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = auth.key; // Save the access token
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

const removeUser = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(USER_DATA_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth();
      if (auth && auth.key) {
        config.headers.Authorization = `Bearer ${auth.key}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
}

export const sendSetAuthMessageToParentWindow = (key: string, value: string, parentOrigin: string) => {
  const message : StoreMessageModel = {
    type: "stokkio-key",
    method: "set",
    key: key,
    value: value,
  };

  window.parent.postMessage(JSON.stringify(message), parentOrigin);
};

export const sendRetrieveAuthMessageToParentWindow = (key: string, parentOrigin: string) => {
  const message : StoreMessageModel = {
    type: "stokkio-key",
    method: "get",
    key: key,
  };

  window.parent.postMessage(JSON.stringify(message), parentOrigin);
}

export const sendAppReadyMessageToParentWindow = () => {
  const message : MessageModel = {
    type: "stokkio-app",
    method: "ready",
  };

  window.parent.postMessage(JSON.stringify(message), '*');
}

export {
  getAuth,
  setAuth,
  removeAuth,
  getUser,
  setUser,
  removeUser,
  AUTH_LOCAL_STORAGE_KEY,
  USER_DATA_LOCAL_STORAGE_KEY,
};
