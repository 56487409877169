/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-refresh/only-export-components */
import { createContext, useContext, useEffect, useState } from "react";
import { ThemeModeComponent } from "../../../assets/ts/layout";
import { toAbsoluteUrl } from "../../../helpers";
import { getLuminance } from "./helpers/colorHelpers";
import { useAuth } from "../../../../app/modules/auth";

export type ThemeModeType = "dark" | "light" | "system";
export const themeModelSKey = "kt_theme_mode_value";
export const themeMenuModeLSKey = "kt_theme_mode_menu";
export const themeEmbeddedModelSKey = "kt_theme_mode_embedded";

const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";

type ThemeModeContextType = {
  mode: ThemeModeType;
  menuMode: ThemeModeType;
  customBtnColor: string | null;
  customTextColor: string | null;
  updateMode: (_mode: ThemeModeType) => void;
  updateMenuMode: (_mode: ThemeModeType) => void;
};

const themeModeSwitchHelper = (_mode: ThemeModeType) => {
  // change background image url
  const mode = _mode !== "system" ? _mode : systemMode;
  const imageUrl =
    "/media/patterns/header-bg" + (mode === "light" ? ".jpg" : "-dark.jpg");
  document.body.style.backgroundImage = `url("${toAbsoluteUrl(imageUrl)}")`;
};

const getThemeModeFromLocalStorage = (lsKey: string): ThemeModeType => {
  if (!localStorage) {
    return "light";
  }

  const data = localStorage.getItem(lsKey);
  if (data === "dark" || data === "light" || data === "system") {
    return data;
  }

  if (document.documentElement.hasAttribute("data-bs-theme")) {
    const dataTheme = document.documentElement.getAttribute("data-bs-theme");
    if (
      dataTheme &&
      (dataTheme === "dark" || dataTheme === "light" || dataTheme === "system")
    ) {
      return dataTheme;
    }
  }

  return "system";
};

const defaultThemeMode: ThemeModeContextType = {
  mode: getThemeModeFromLocalStorage(themeModelSKey),
  customBtnColor: null,
  customTextColor: null,
  menuMode: getThemeModeFromLocalStorage(themeMenuModeLSKey),
  updateMode: (_mode: ThemeModeType) => {},
  updateMenuMode: (_menuMode: ThemeModeType) => {},
};

const ThemeModeContext = createContext<ThemeModeContextType>({
  mode: defaultThemeMode.mode,
  customBtnColor: defaultThemeMode.customBtnColor,
  customTextColor: defaultThemeMode.customTextColor,
  menuMode: defaultThemeMode.menuMode,
  updateMode: (_mode: ThemeModeType) => {},
  updateMenuMode: (_menuMode: ThemeModeType) => {},
});

const useThemeMode = () => useContext(ThemeModeContext);

const ThemeModeProvider = ({ children }: { children: React.ReactNode }) => {
  const { parentOrigin } = useAuth();
  const [mode, setMode] = useState<ThemeModeType>(defaultThemeMode.mode);
  const [menuMode, setMenuMode] = useState<ThemeModeType>(
    defaultThemeMode.menuMode
  );
  const [customBtnColor, setCustomBtnColor] = useState<string | null>(
    defaultThemeMode.customBtnColor
  );

  const [customTextColor, setCustomTextColor] = useState<string>(
    customBtnColor
      ? getLuminance(customBtnColor) > 0.5
        ? "text-dark"
        : "text-white"
      : "text-white"
  );

  const isEmbedded = () => window.self !== window.top;

  const updateMode = (
    _mode: ThemeModeType,
    saveInLocalStorage: boolean = true
  ) => {
    setMode(_mode);

    // themeModeSwitchHelper(updatedMode)
    if (saveInLocalStorage && localStorage && !isEmbedded()) {
      localStorage.setItem(themeModelSKey, _mode);
    }
    
    if(saveInLocalStorage && localStorage && isEmbedded()){
      localStorage.setItem(themeEmbeddedModelSKey, _mode);
    }

    if (saveInLocalStorage) {
      const updatedMode = _mode === "system" ? systemMode : _mode;
      document.documentElement.setAttribute("data-bs-theme", updatedMode);
    }
    ThemeModeComponent.init();
  };

  const updateMenuMode = (
    _menuMode: ThemeModeType,
    saveInLocalStorage: boolean = true
  ) => {
    setMenuMode(_menuMode);
    if (saveInLocalStorage && localStorage) {
      localStorage.setItem(themeMenuModeLSKey, _menuMode);
    }
  };

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      switch (event.data.type) {
        case "stokkio-theme-change":
          updateMode(event.data.theme);
          break;
        case "stokkio-button-change":
          setCustomBtnColor(event.data.color);
          break;
        default:
          break;
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    updateMode(mode, false);
    updateMenuMode(menuMode, false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCustomTextColor(
      customBtnColor
        ? getLuminance(customBtnColor) > 0.5
          ? "text-dark"
          : "text-white"
        : "text-white"
    );
  }, [customBtnColor]);

  return (
    <ThemeModeContext.Provider
      value={{
        mode,
        menuMode,
        customBtnColor,
        customTextColor,
        updateMode,
        updateMenuMode,
      }}
    >
      {children}
    </ThemeModeContext.Provider>
  );
};

export { ThemeModeProvider, useThemeMode, systemMode, themeModeSwitchHelper };
