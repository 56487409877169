import { FC } from "react";
import { PastEventModel } from "../core/_models";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { formatQuestionDate } from "../core/_helpers";

interface PastEventProps {
  pastEvent: PastEventModel;
  index: number;
  eventId: string;
}

const PastEvent: FC<PastEventProps> = ({ pastEvent, eventId }) => {

  const handleClick = () => {
    var currentUrl = window.location.href;
    window.location.href = currentUrl.replace(eventId, pastEvent.id.toString());
  }

  return (
    <div className="card card-body p-4 m-3 mt-0 mb-0 gray-body-color d-flex flex-row past-event-card" onClick={handleClick}>
      <div className="symbol symbol-50px col-3 align-self-center symbol-background">
        <img
          src={pastEvent.cover ? pastEvent.cover : toAbsoluteUrl("media/avatars/blank.png")}
          alt="Event cover"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.onerror = null;
            target.src = toAbsoluteUrl("media/avatars/blank.png");
          }}
          style={{ objectFit: 'cover' }}
        ></img>
      </div>
      <div className="d-flex flex-column px-5">
        <h4 >{pastEvent.title}</h4>
        <h5 className="text-muted">{formatQuestionDate(pastEvent.start_date)}</h5>
      </div>
    </div>
  );
};

export default PastEvent;
