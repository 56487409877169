import { FC, useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { useThemeMode } from "../../../partials";
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";

interface FooterEmbeddedProps {}

const FooterEmbedded: FC<FooterEmbeddedProps> = () => {
  const { mode } = useThemeMode();
  const { eventId } = useParams<{ eventId: string }>();

  return (
    <div
      className="w-100 position-fixed bottom-0 bg-body d-flex justify-content-between align-items-center px-5"
      style={{ height: 30, borderTop: "solid 1px #000000", zIndex: 100 }}
    >
      {mode == "light" ? (
        <img
          style={{ height: "50%" }}
          alt="Logo Stokk.io"
          src={toAbsoluteUrl("media/logos/stokkio/logo-black.svg")}
        />
      ) : (
        <img
          style={{ height: "50%" }}
          alt="Logo Stokk.io"
          src={toAbsoluteUrl("media/logos/stokkio/logo-white-large.svg")}
        />
      )}
      {eventId && (
        <a
          href={`https://stokk.io/app/event/${eventId}`}
          target="_blank"
          rel="noreferrer"
          className={`text-decoration-underline ${mode == "light" ? "text-dark" : "text-white"}`}
        >
          Open full size
        </a>
      )}
    </div>
  );
};

export { FooterEmbedded };
