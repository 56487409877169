import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../../helpers";
import { Modal } from "react-bootstrap";
import {
  PauseIcon,
  PlayIcon,
  SeekBackward10Icon,
  SeekForward10Icon,
} from "@vidstack/react/icons";
import { PlayButton } from "@vidstack/react";
import Transcript from "../../../../app/pages/event/layouts/components/transcript/TranscriptWrapper";
import { SuspensedView } from "../../../../app/routing/PrivateRoutes";
import { Color } from "../../notification/Notification";
import { isMobile } from "../../../../app/pages/event/core/_helpers";
import TranscriptWrapper from "../../../../app/pages/event/layouts/components/transcript/TranscriptWrapper";
import { LanguageModel } from "../../../../app/pages/event/core/_models";
import CustomSlider from "./components/CustomSlider";

interface EventTranscriptModalProps {
  show: boolean;
  handleClose: () => void;
  playerTime: React.MutableRefObject<number>;
  createNotification: (color: Color, message: string) => void;
  videoLength: number;
  seekedTime: number;
  isPlayerPlaying: React.MutableRefObject<boolean>;
  onSeekTime: (time: number) => void;
  availableLanguages?: LanguageModel[];
  onPlay: (play: boolean) => void;
  title: string;
  company: string;
  width: number;
  eventId: string;
  hasTranscript?: boolean;
  onFailedToFetchTranscript: () => void;
}

const EventTranscriptModal: FC<EventTranscriptModalProps> = ({
  show,
  handleClose,
  playerTime,
  videoLength,
  onSeekTime,
  onPlay,
  isPlayerPlaying,
  seekedTime,
  title,
  company,
  width,
  eventId,
  hasTranscript,
  onFailedToFetchTranscript,
  createNotification,
  availableLanguages,
}) => {
  const [touched, setTouched] = useState(false);
  const [seekedTimeModal, setSeekedTimeModal] = useState(0);
  const [newSliderValue, setNewSliderValue] = useState(0);
  const touchedRef = useRef(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    setIsPlaying(isPlayerPlaying.current);
  }, [isPlayerPlaying]);

  useEffect(() => {
    setSeekedTimeModal(seekedTime);
  }, [seekedTime]);

  useEffect(() => {}, [show]);
  const handleSeekBack = () => {
    onSeekTime(Number(playerTime.current) * 1000  - 10000);
    setNewSliderValue(playerTime.current);
  };

  const handleSeekForth = () => {
    onSeekTime(Number(playerTime.current) * 1000 + 10000);
    setNewSliderValue(playerTime.current);
  };

  const handleWordClicked = (time: number) => {
    playerTime.current = time / 1000;
    onSeekTime(time);
    updateSliderIfPlayerStopped()
  };

  const updateSliderIfPlayerStopped = () => {
    setNewSliderValue(playerTime.current);
  };

  const handlePlay = () => {
    onPlay(true);
    setIsPlaying(true);
  };

  const handlePause = () => {
    onPlay(false);
    setIsPlaying(false);
  };

  useEffect(() => {
    touchedRef.current = touched;
  }, [touched]);

  return (
    <Modal
      id="kt_modal_event_transcript"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-fullscreen mw-400px"
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content shadow-none">
          <div className="modal-header">
            <h1 className="modal-title">{company}</h1>
            <h2 className="modal-title">{title}</h2>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <KTSVG
                path="media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body pb-0">
              <TranscriptWrapper
                onFailedToFetchTranscript={onFailedToFetchTranscript}
                updateTags={false}
                createNotification={createNotification}
                availableLanguages={availableLanguages}
                eventId={eventId}
                hasTranscript={hasTranscript}
                width={width}
                onWordClick={handleWordClicked}
                playerTime={playerTime}
                seekedTime={seekedTimeModal}
              ></TranscriptWrapper>
          </div>
          <div className="modal-footer custom-footer">
            {!isPlaying ? (
              <button
                className="btn btn-icon btn-secondary btn-sm me-2"
                onClick={handlePlay}
              >
                {isMobile() ? (
                  <i className="ki-outline ki-to-right fs-2"></i>
                ) : (
                  <PlayIcon></PlayIcon>
                )}
              </button>
            ) : (
              <button
                className="btn btn-icon btn-secondary btn-sm me-2"
                onClick={handlePause}
              >
                {isMobile() ? (
                  <i className="ki-outline ki-row-vertical"></i>
                ) : (
                  <PauseIcon></PauseIcon>
                )}
              </button>
            )}

            <button
              className="btn btn-icon btn-secondary btn-sm me-2"
              onClick={handleSeekBack}
            >
              {isMobile() ? (
                <i className="ki-outline ki-double-left"></i>
              ) : (
                <SeekBackward10Icon></SeekBackward10Icon>
              )}
            </button>
            <button
              className="btn btn-icon btn-secondary btn-sm me-5"
              onClick={handleSeekForth}
            >
              {isMobile() ? (
                <i className="ki-outline ki-double-right"></i>
              ) : (
                <SeekForward10Icon></SeekForward10Icon>
              )}
            </button>
            <CustomSlider isPlaying={isPlayerPlaying} videoLength={videoLength} playerTime={playerTime} onSeekTime={onSeekTime} newSliderValue={newSliderValue}></CustomSlider>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EventTranscriptModal;
