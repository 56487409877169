import axios from "axios";
import { iframeSettings } from "../WidgetEditor";

const API_URL = import.meta.env.VITE_APP_API_URL_BASE;

export function postIframeSettings(company: string, settings: iframeSettings, origins: string[]) {
  var csrf = getCookie('csrftoken');
  return axios.post(`${API_URL}/core/update-iframe-settings/${company}/`, {origins: origins, iframe_settings: settings},{
    headers: {
      "X-CSRFToken": csrf,
    },
    withCredentials: true,
  });
}

export function getIframeSettings(company: string) {
  return axios.get(`${API_URL}/core/iframe-settings/${company}/` ,{
    withCredentials: true,
  });
}

function getCookie(name: string) {
  const value = `${document.cookie}`;
  const parts = value.split(`${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
}