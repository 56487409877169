import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { getSemanticSearchResults } from "../../../../core/_requests";
import {
  AllSearchResults,
  SemanticSearchResults,
  LanguageModel,
} from "../../../../core/_models";
import { Color } from "../../../../../../../_metronic/partials/notification/Notification";
import { useAuth } from "../../../../../../modules/auth";
import { useThemeMode } from "../../../../../../../_metronic/partials";

interface SemanticSearchProps {
  eventId: string;
  currentIndexChange: number;
  allSearchResults: AllSearchResults;
  language: LanguageModel | undefined;
  embedded?: boolean;
  onSetSearchQUery: (query: string) => void;
  onResultsRecieved: (results: SemanticSearchResults) => void;
  onIsSearching: (isSearching: boolean) => void;
  createNotification: (color: Color, message: string) => void;
  onChangeIndex: (index: number) => void;
}

const SemanticSearch = forwardRef((props:SemanticSearchProps ,ref) => {
  const { customBtnColor, customTextColor } = useThemeMode();
  const { key } = useAuth();
  const [searchValue, setSearchValue] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const searchValueRef = useRef(searchValue);
  const [wasSearched, setWasSearched] = useState(false);

  useImperativeHandle(ref, () => ({
    getSemanticSearch,
  }));

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") getSemanticSearch();
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (props.currentIndexChange !== currentIndex) {
      setCurrentIndex(props.currentIndexChange);
    }
  }, [props.currentIndexChange]);

  useEffect(() => {
    searchValueRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    props.onChangeIndex(currentIndex);
  }, [currentIndex]);

  const getSemanticSearch = async () => {
    if (searchValueRef.current.trim() == "") {
      setSearchValue("");
      return;
    }

    try {
      setCurrentIndex(0);
      props.onSetSearchQUery(searchValueRef.current);
      props.onIsSearching(true);
      setWasSearched(true);
      let response = await getSemanticSearchResults(
        props.eventId,
        searchValueRef.current,
        props.language,
        key
      );
      
      props.onResultsRecieved(response.data);
      props.onIsSearching(false);
    } catch (error) {
      props.onIsSearching(false);
      props.createNotification("error", "Error occured while searching");
    }
  };


  return (
    <div className={`input-group ${props.embedded ? "mb-1" : "mb-5"}`}>
      <input
        type="text"
        className="form-control form-contoll-pill-left"
        placeholder="Search"
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      ></input>
      <span
        role="button"
        className={`input-group-text form-contoll-pill-right ps-3 ${
          searchValue.trim() == ""
            ? "disabled"
            : customBtnColor ? "btn-iframe-custom" : "search-clickable btn-clickable"
        }`}
        style={
          customBtnColor
            ? { "--custom-btn-color": customBtnColor }
            : {}
        }
        id="search"
        onClick={getSemanticSearch}
      >
        <i
          className={`ki-outline ki-magnifier fs-2 ${
            searchValue.trim() == "" ? "" : customTextColor ? customTextColor:"text-white"
          }`}
        ></i>
      </span>
      <a
        className={`btn btn-secondary btn-icon btn-round ms-3 z-index-1 ${wasSearched ? "":"d-none"}`}
        onClick={() => {
          if (props.allSearchResults.results.length - 1 >= currentIndex + 1) {
            setCurrentIndex((prev) => prev + 1);
          }
        }}
      >
        <i className="ki-outline ki-arrow-down fs-1"></i>
      </a>
      <a
        className={`btn btn-secondary btn-icon btn-round ms-3 z-index-1 ${wasSearched ? "":"d-none"}`}
        onClick={() => {
          if (currentIndex - 1 >= 0) {
            setCurrentIndex((prev) => prev - 1);
          }
        }}
      >
        <i className="ki-outline ki-arrow-up fs-1"></i>
      </a>
    </div>
  );
});

export default SemanticSearch;
