export const getCode = (color: string, height: number, theme: string, company?: string) => {
return `<iframe
  id="stokkio-event-iframe"
  src="https://app.stokk.io/app/company/embedded/${company}/events"
  width="100%"
  height=${height}
  data-theme="${theme}"
  data-button-color="${color}"
  style="border: none; user-select: none;"
></iframe>
<script
  src="https://app.stokk.io/iframe-integration-script.js"
  type="module"
></script>
<script>
  function changeTheme(theme) {
    const iframe = document.getElementById("stokkio-event-iframe");
    iframe.contentWindow.postMessage(
      { type: "stokkio-theme-change", theme },
      "https://app.stokk.io/"
    );
  }
</script>`
}