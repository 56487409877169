import {
  createRef,
  FC,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  TaggedContentModel,
  TagToRenderModel,
  UtteranceModel,
} from "../core/_models";
import { setTags } from "@sentry/react";
import {
  findSpanEnd,
  findSpanStart,
  getOffsetTopRelativeToTranscriptEditor,
  getTranscriptEditorOffsetTop,
} from "../core/_helpers";

interface TagsPros {
  tags: TagToRenderModel[] | null;
  utterances: UtteranceModel[];
  isToggled: boolean;
}

const Tags: FC<TagsPros> = ({ tags, utterances, isToggled }) => {
  const [tagsToRender, setTagsToRender] = useState<TagToRenderModel[] | null>(
    null
  );
  const tagsPrevLength = useRef<number>(0);
  const tagHighlightRefs = useRef<React.RefObject<HTMLDivElement>[]>([]);
  const tagLabelRefs = useRef<React.RefObject<HTMLDivElement>[]>([]);

  if (tagsToRender) {
    tagsToRender.forEach((tag, index) => {
      if (!tagHighlightRefs.current[index]) {
        tagHighlightRefs.current[index] = createRef<HTMLDivElement>();
      }
    });
  }

  if (tagsToRender) {
    tagsToRender.forEach((tag, index) => {
      if (!tagHighlightRefs.current[index]) {
        tagHighlightRefs.current[index] = createRef<HTMLDivElement>();
      }
      if (!tagLabelRefs.current[index]) {
        tagLabelRefs.current[index] = createRef<HTMLDivElement>();
      }
    });
  }

  useEffect(() => {
    if (tags && tagsPrevLength.current > tags.length) {
      let tagsCopy = [...tags];
      tagsCopy.forEach((tag, index) => {
        tag.left = 0;
      });
      setTagsToRender(tagsCopy);
    } else {
      setTagsToRender(tags);
      if (tags) tagsPrevLength.current = tags.length;
    }
  }, [tags]);

  function isIntersecting(element1: HTMLDivElement, element2: HTMLDivElement) {
    const rect1 = element1.getBoundingClientRect();
    const rect2 = element2.getBoundingClientRect();
    return (
      rect1.top < rect2.bottom &&
      rect1.bottom > rect2.top &&
      rect1.left < rect2.right &&
      rect1.right > rect2.left
    );
  }

  useEffect(() => {
    if (tagsToRender !== null) {
      let tagsCopy = [...tagsToRender];
      let isChanged = false;
      let updatedElements = new Set<HTMLDivElement>();
      for (let i = 0; i < tagHighlightRefs.current.length; i++) {
        const refBar1 = tagHighlightRefs.current[i];
        const refLabel1 = tagLabelRefs.current[i];
        for (let j = i + 1; j < tagHighlightRefs.current.length; j++) {
          const refBar2 = tagHighlightRefs.current[j];
          const refLabel2 = tagLabelRefs.current[j];
          if (
            refBar1.current &&
            refBar2.current &&
            isIntersecting(refBar1.current, refBar2.current) &&
            !updatedElements.has(refBar2.current)
          ) {
            if (!tagsCopy[j].hasOwnProperty("left")) {
              tagsCopy[j].left = 0;
            }
            tagsCopy[j].left += 10;
            updatedElements.add(refBar2.current);
            isChanged = true;
          }
          if (
            refLabel1.current &&
            refLabel2.current &&
            refBar1.current &&
            refBar2.current &&
            (isIntersecting(refLabel1.current, refBar2.current) ||
              isIntersecting(refLabel2.current, refBar1.current))
          ) {
            if (!tagsCopy[j].hasOwnProperty("left")) {
              tagsCopy[j].left = 0;
            }
            tagsCopy[j].left += 10;
            isChanged = true;
            updatedElements.add(refBar2.current);
          }
        }
      }
      if (isChanged) {
        setTagsToRender(tagsCopy);
      }
    }
  }, [tagsToRender]);

  return (
    <div className={`tag-column ${isToggled ? "" : "w-0"}`}>
      {tags &&
        tags.map((tag, index) => (
          <div
            key={index}
            className="tag-wrapper d-flex align-items-start"
            style={{
              height: tag.height,
              width: "min-content",
              left: tag.left + "px",
              top: tag.topOffest,
            }}
          >
            <div
              ref={tagHighlightRefs.current[index]}
              className="tag-highlight"
              style={{
                height: tag.height,
                width: "5px",
                backgroundColor: tag.color,
                borderRadius: "5px",
                marginRight: "5px",
              }}
            ></div>
            <div className="tag-label" ref={tagLabelRefs.current[index]}>
              <p style={{ color: tag.color }}>{tag.name}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Tags;
