import {
  MediaPlayer,
  MediaPlayerInstance,
  MediaProvider,
} from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
  DefaultAudioLayout,
} from "@vidstack/react/player/layouts/default";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

interface PlayerProps {
  videoUrl: string;
  eventTitle?: string;
  embedded?: boolean;
  newPlayerTime: number;
  onTimeUpdate: (currentTime: number) => void;
  onSeeked: (currentTime: number) => void;
  onDurationLoaded: (duration?: number) => void;
  onPlay: (play: boolean) => void;
  isPlaying: boolean;
}

const Player = forwardRef((props: PlayerProps, ref) => {
  const player = useRef<MediaPlayerInstance>(null);
  const [playerTime, setPlayerTime] = useState(0);
  const currentTimeRef = useRef(0);

  useEffect(() => {
    setPlayerTime(props.newPlayerTime);
  }, [props.newPlayerTime]);

  useEffect(() => {
    if (player.current && props.embedded) {
      document.getElementsByTagName("video")[0]?.style.setProperty("width", "100%");
      document.getElementsByTagName("video")[0]?.style.setProperty("height", "100%");
    }
  }, []);

  useImperativeHandle(ref, () => ({
    seekBack,
    seekForth,
  }));

  useEffect(() => {
    if (props.isPlaying) {
      player.current?.play();
    } else {
      player.current?.pause();
    }
  }, [props.isPlaying]);

  const seekTime = (time: string) => {
    setPlayerTime(Number(time) / 1000);
  };

  const seekBack = () => {
    if (player.current) {
      setPlayerTime(currentTimeRef.current - 10);
    }
  };

  const seekForth = () => {
    if (player.current) {
      setPlayerTime(currentTimeRef.current + 10);
    }
  };

  return (
    <>
      <MediaPlayer
        id="player"
        className={`${
          props.embedded
            ? "max-width-video max-height-video-embedded rounded-0"
            : "max-height-video"
        }`}
        ref={player}
        title={props.eventTitle ? props.eventTitle : "No Event Title"}
        currentTime={playerTime}
        src={props.videoUrl}
        playsinline
        onPlay={() => props.onPlay(true)}
        onPause={() => props.onPlay(false)}
        onSeeked={(num) => {
          props.onSeeked(num * 1000);
        }}
        onTimeUpdate={(event) => {
          currentTimeRef.current = event.currentTime;
          props.onTimeUpdate(event.currentTime);
        }}
        onLoadedMetadata={() => {
          props.onDurationLoaded(player.current?.state.duration);
        }}
      >
        <MediaProvider />
        <DefaultVideoLayout icons={defaultLayoutIcons}></DefaultVideoLayout>
        <DefaultAudioLayout icons={defaultLayoutIcons}></DefaultAudioLayout>
      </MediaPlayer>
    </>
  );
});

export { Player };
