import { FC, useEffect, useState } from "react";
import { KTIcon, KTSVG } from "../../../helpers";
import { UtteranceModel } from "../../../../app/pages/transcript/core/_models";
import { Modal } from "react-bootstrap";
import { InvestorDetailsModel } from "../../../../app/pages/event/core/_models";
import { checkIfUserSignedUp } from "../../../../app/pages/event/core/_requests";
import { useLocation } from "react-router-dom";
import { Color } from "../../notification/Notification";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";

const BASE_URL = import.meta.env.VITE_APP_BACKEND_URL;

interface EventEmailModalProps {
  show: boolean;
  onCreateNotification: (color: Color, message: string) => void;
  signup: string;
  login: string;
  embedded?: boolean;
  handleClose: () => void;
  handleSubmited: () => void;
  handleLogin: (email: string) => void;
  handleSignup: (email: string) => void;
}

const EventEmailModal: FC<EventEmailModalProps> = ({
  handleClose,
  handleSubmited,
  onCreateNotification,
  login,
  embedded,
  signup,
  show,
  handleLogin,
  handleSignup,
}) => {
  const { customBtnColor, customTextColor } = useThemeMode();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");

  const location = useLocation();

  const participate = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    let valid = email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (valid) {
      setIsValid(true);
    } else {
      setIsValid(false);
      if (email.length == 0) {
        onCreateNotification("error", "Please provide your email to cotinue.");
      } else {
        onCreateNotification("error", "Incorrect email format.");
      }
      return;
    }

    try {
      var response = await checkIfUserSignedUp(email);

      if (response.status == 200) {
        if (response.data.exists == true) {
          handleLogin(email);
          handleClose();
        } else {
          handleSignup(email);
          handleClose();
        }
      }
    } catch (error) {
      onCreateNotification(
        "error",
        "Something went wrong! Please try again later."
      );
    }
  };

  const handleLinkedInRedirect = () => {
    window.location.href = `${BASE_URL}/account/oidc/linkedin_oidc/login/?next=app/fe${location.pathname}&action=sign_up`;
  };

  return (
    <Modal
      id="kt_modal_event_sign_up"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-400px"
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className="modal-header">
        <h3 className="my-0">
          {embedded ? "Sign in with your Stokk.io account or create a new account to access this event" : "You need to sign up before participating in this event."}
          
        </h3>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleClose}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>
      <div className="modal-body d-flex flex-column py-lg-5 px-lg-10">
        <></>
        {!embedded && (
          <div className="d-grid">
            <a
              onClick={handleLinkedInRedirect}
              className="w-100 btn btn-linkedin"
            >
              <i className="fab fa-linkedin fs-1"></i>{" "}
              <span className="fw-bold">Sign up with LinkedIn</span>
            </a>
          </div>
        )}
        {!embedded && (
          <div className="separator separator-content border-secondary my-10">
            OR
          </div>
        )}
        <div className="form-floating mb-7">
          <input
            className={`form-control ${isValid ? "" : "is-invalid"}`}
            type="text"
            name="email"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <label className="text-muted">Your email</label>
        </div>
        <a
          href="#"
          className={`btn ${
            customBtnColor ? "btn-iframe-custom " + customTextColor : "btn-primary"
          } fs-4`}
          style={customBtnColor ? { "--custom-btn-color": customBtnColor } : {}}
          onClick={participate}
        >
          Submit
        </a>
      </div>
    </Modal>
  );
};

export { EventEmailModal };
