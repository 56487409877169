import { FC, useEffect, useState } from "react";
import { EventStatus, QuestionModel } from "../../../../app/pages/event/core/_models";
import { Modal } from "react-bootstrap";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import Question from "../../../../app/pages/event/components/Question";
import { useAuth } from "../../../../app/modules/auth";
import { Color } from "../../notification/Notification";
import Answers from "../../../../app/pages/event/components/Answers";
import { SuspensedView } from "../../../../app/routing/PrivateRoutes";
import {
  systemMode,
  useThemeMode,
} from "../../layout/theme-mode/ThemeModeProvider";
import { Player } from "../../../../app/pages/event/layouts/components/Player";
import { on } from "events";

interface AnswerModalProps {
  show: boolean;
  userParticipation: boolean;
  company: string;
  handleClose: () => void;
  question: QuestionModel;
  createNotification: (color: Color, message: string) => void;
  eventTitle: string;
  videoUrl?: string;
  eventStatus: EventStatus;
  onQuestionUpdated: (question: QuestionModel) => void;
  isModerated: boolean; 
}

const AnswerModal: FC<AnswerModalProps> = ({
  show,
  videoUrl,
  question,
  handleClose,
  company,
  createNotification,
  eventTitle,
  userParticipation,
  eventStatus,
  isModerated,
  onQuestionUpdated
}) => {
  const { currentUser } = useAuth();
  const [watchAnswer, setWatchAnswer] = useState<boolean>(false);
  const [timeStamp, setTimeStamp] = useState<number>(0);

  useEffect(() => {
    if (timeStamp > 0) {
      setWatchAnswer(true);
    }
  }, [timeStamp]);

  const handleWatchAnswer = (timestamp: number) => {
    if (timeStamp == timestamp) {
      setWatchAnswer(true);
    }
    setTimeStamp(timestamp / 1000);
    scrollToPlayer();
  };

  const closePlayer = () => {
    setTimeStamp(0);
    setWatchAnswer(false);
  };

  useEffect(() => {
    if(watchAnswer == true){
      scrollToPlayer();
    }
  }, [watchAnswer]);

  const scrollToPlayer = () => {
    let player = document.getElementById("answer-player")
    if(player){
      player.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <Modal
      id="kt_modal_event_transcript"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-lg"
      show={show}
      onHide={() => {
        handleClose();
        closePlayer();
      }}
      backdrop={true}
    >
      <div className="modal-content shadow-none">
        <div className="modal-header">
          <h1 className="modal-title">Question details</h1>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={()=>{handleClose(); closePlayer();}}
          >
            <KTSVG
              path="media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className="modal-body pb-0 modal-body-scrollable">
          <Question
            userParticipation={userParticipation}
            onQuestionUpdated={onQuestionUpdated}
            onUpvoteNotSignedIn={() => {createNotification("info", "You have to be signed in to upvote a question.")}}
            handleDeleteQuestion={() => {createNotification("info", "Please leave this modal and delte the question from the event page.")}}
            eventStatus={eventStatus}
            isModerated={isModerated}
            question={question} 
            createNotification={createNotification}
            isInsideModal={true}
            currentUser={currentUser}
          ></Question>
          <div className="answers-wrapper mt-7">
            <h1 className="text-muted fs-5 mb-5">Answers:</h1>
            <SuspensedView additionalFallback={<AnswersFallback />}>
              <Answers
                eventTitle={eventTitle}
                questionId={question.id}
                company={company}
                onWatchAnswerClick={handleWatchAnswer}
              ></Answers>
            </SuspensedView>
          </div>
          {watchAnswer && videoUrl && (
            <div id="answer-player" className="mb-5">
              <Player
                videoUrl={videoUrl}
                newPlayerTime={timeStamp}
                onSeeked={() => {}}
                onTimeUpdate={() => {}}
                onDurationLoaded={() => {}}
                onPlay={() => {}}
                isPlaying={false}
              ></Player>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AnswerModal;

const AnswersFallback: React.FC = () => {
  const { mode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  return (
    <div className="d-flex justify-content-center">
      <div className="d-flex flex-column justify-content-center">
        <span className="spinner-border spinner-border-large align-self-center text-muted"></span>

        <p className="font-weight-bold mt-4 align-self-center text-muted fs-5">
          Loading the answers
        </p>
        <p className="indicator-progress"></p>
      </div>
    </div>
  );
};
