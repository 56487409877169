/* eslint-disable react-refresh/only-export-components */
import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import {
  LayoutSplashScreen,
  useLayout,
} from "../../../../_metronic/layout/core";
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import {
  checkOrigin,
  getSession,
  getSessionWithKey,
  getUser,
} from "./_requests";
import { WithChildren } from "../../../../_metronic/helpers";
import {
  Color,
  NotificationsContainerHandle,
} from "../../../../_metronic/partials/notification/Notification";
import { useLocation, useNavigate } from "react-router-dom";
import { clearOldDrafts } from "../../../pages/transcript/core/_draftHelpers";

type AuthContextProps = {
  auth: AuthModel | undefined;
  currentUser: UserModel | undefined;
  localUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  key: string | undefined;
  parentOrigin: string | undefined;
  originNotAllowed: boolean;
  waitingForParentOrigin: boolean;
  saveKey: (key: string) => void;
  getKey: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  currentUser: undefined,
  localUser: undefined,
  key: undefined,
  parentOrigin: undefined,
  originNotAllowed: false,
  waitingForParentOrigin: true,
  saveKey: () => {},
  getKey: () => {},
  setCurrentUser: () => {},
};

const envMode = import.meta.env.MODE;

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const isEmbedded = () => {
  return window.self !== window.top;
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [localUser, setLocalUser] = useState<UserModel | undefined>(
    authHelper.getUser()
  );
  //Key used when event is embedded
  const [key, setKey] = useState<string | undefined>("");
  //Parent origin used when event is embedded
  const [parentOrigin, setParentOrigin] = useState<string | undefined>("");
  const [originNotAllowed, setOriginNotAllowed] = useState<boolean>(false);
  const [waitingForParentOrigin, setWaitingForParentOrigin] =
    useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      switch (event.data.type) {
        case "stokkio-key":
          setKey(event.data.value);
          break;
        case "stokkio-parent-origin":
          //If in development mode, do not check the origin
          if(envMode === "development") {
            setWaitingForParentOrigin(false);
            setParentOrigin(event.origin);
            break;
          }
          //If the origin is stokk.io, do not check the origin 
          if(event.origin === "https://www.stokk.io" || event.origin === "https://app.stokk.io") {
            setWaitingForParentOrigin(false);
            setParentOrigin(event.origin);
            break;
          }
          //Check the origin agains api and set it if allowed
          try {
            let response = await checkOrigin(
               event.origin
            );
            if (response.data.allowed) {
              setWaitingForParentOrigin(false);
              setParentOrigin(event.origin);
            } else {
              setOriginNotAllowed(true);
            }
          } catch {
            setOriginNotAllowed(true);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener("message", handleMessage);

    if (window.self !== window.top) {
      authHelper.sendAppReadyMessageToParentWindow();
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    getKey();
  }, [parentOrigin]);

  const saveKey = (value: string) => {
    if (parentOrigin) {
      setKey(value);
      authHelper.sendSetAuthMessageToParentWindow(
        "stokkio-key",
        value,
        envMode === "development" ? "*" : parentOrigin
      );
    }
  };

  const getKey = () => {
    if (parentOrigin) {
      authHelper.sendRetrieveAuthMessageToParentWindow(
        "stokkio-key",
        envMode === "development" ? "*" : parentOrigin
      );
    }
  };

  //   const saveAuth = (auth: AuthModel | undefined) => {
  //   setAuth(auth)
  //   if (auth) {
  //     authHelper.setAuth(auth) // Save the access token
  //   } else {
  //     authHelper.removeAuth()
  //   }
  // }

  // const saveUser = (user: UserModel | undefined) => {
  //   setLocalUser(user)
  //   if (user) {
  //     authHelper.setUser(user) // Save user
  //   } else {
  //     authHelper.removeUser()
  //   }
  // }

  // const logout = () => {
  //   // saveAuth(undefined)
  //   // saveUser(undefined)
  //   setCurrentUser(undefined)
  // }

  return (
    <AuthContext.Provider
      value={{
        auth,
        currentUser,
        localUser,
        setCurrentUser,
        key,
        parentOrigin,
        saveKey,
        getKey,
        originNotAllowed,
        waitingForParentOrigin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, currentUser, localUser, setCurrentUser, key } = useAuth();
  const { config, setLayout } = useLayout();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const notificationsRef = useRef<NotificationsContainerHandle | null>(null);
  const { BASE_URL } = import.meta.env;
  const REDIRECT_URL = import.meta.env.VITE_APP_TEMPORARY_ROOT_REDIRECT;

  const createNewNotification = (color: Color, message: string) => {
    if (!notificationsRef.current) return;
    notificationsRef.current?.createNotification(color, message);
  };

  useEffect(() => {
    const requestUser = async () => {
      try {
        let response;
        if (isEmbedded()) {
          response = await getSessionWithKey(key);
        } else {
          response = await getSession();
        }

        if (response.data.isAuthenticated) {
          var user = await getUser(key);
          setCurrentUser(user.data);
        }
      } catch {
        createNewNotification("error", "Invalid Token");
      }
    };

    if (key) {
      requestUser();
    }
  }, [key]);

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        let response;
        if (isEmbedded()) {
          response = await getSessionWithKey(key);
        } else {
          response = await getSession();
        }
        if (response.data.isAuthenticated) {
          var user = await getUser(key);
          setCurrentUser(user.data);
          if (window.location.pathname === BASE_URL) {
            window.location.href = REDIRECT_URL;
            return;
          }
          clearOldDrafts();
          setShowSplashScreen(false);
        }
        if (window.location.pathname === BASE_URL) {
          window.location.href = REDIRECT_URL;
          return;
        }
        setShowSplashScreen(false);
      } catch (error) {
        setShowSplashScreen(false);
        navigate("error/500");
      }
    };
    requestUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    var newConfig = { ...config };
    if (!currentUser) {
      newConfig.isPublic = true;
    } else {
      newConfig.isPublic = false;
    }
    setLayout(newConfig);
  }, [currentUser]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
