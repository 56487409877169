import React, { FC } from "react";
import { SemanticSearchResults } from "../../../../core/_models";
import { formatAnswerTime } from "../../../../core/_helpers";
import { useThemeMode } from "../../../../../../../_metronic/partials";

interface SearchResultsProps {
  searchResults?: SemanticSearchResults;
  searchQuery: string;
  displaySearchResults: boolean;
  isSearching: boolean;
  width: number;
  embedded?: boolean;
  onScrollToResult: (resultStart: number, resultEnd: number) => void;
}

const SearchResults: FC<SearchResultsProps> = ({
  searchResults,
  embedded,
  searchQuery,
  isSearching,
  width,
  displaySearchResults,
  onScrollToResult,
}) => {
  const { customBtnColor, customTextColor } = useThemeMode();
  const srollToResult = (resultStart: number, resultEnd: number) => {
    onScrollToResult(resultStart, resultEnd);
  };

  return (
    <>
      <div
        className={` ${
          width > 576
            ? displaySearchResults
              ? " mb-5 me-5 ms-5 width-40"
              : "minimize-search mb-0 me-0"
            : displaySearchResults
            ? " mb-5 me-5 ms-5 mobile-expanded"
            : "minimize-search mb-0 me-0"
        } ${embedded ? "h-100" : "search-results"}
            `}
      >
        <div className="results-header d-flex justify-content-between w-100 mb-3">
          <div className="d-flex flex-column">
            <p className="mb-0 text-muted">
              {isSearching
                ? "Searching the transcript for:"
                : searchResults !== undefined
                ? `Found ${searchResults.results.length} results for:`
                : "No results found for:"}
            </p>
            <div
              style={
                customBtnColor ? { "--custom-btn-color": customBtnColor } : {}
              }
              className={`badge ${
                customBtnColor
                  ? "btn-iframe-custom " + customTextColor
                  : "badge-primary"
              } min-width`}
            >
              {searchQuery}
            </div>
          </div>
        </div>
        {isSearching ? (
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        ) : (
          <>
            {searchResults !== undefined ? (
              <div
                className={`results-body d-flex ${
                  width > 576 ? " flex-column h-100" : "flex-row"
                }`}
              >
                {searchResults.results.map((result, index) => (
                  <div
                    key={index}
                    className={`result d-flex flex-column ${
                      width > 576
                        ? "height-min-content w-100"
                        : "result-min-width justify-content-start me-5"
                    }`}
                    onClick={() => srollToResult(result.start, result.end)}
                  >
                    <div className="d-flex justify-content-between">
                      <h1 className="fs-6 mb-0">{result.speaker}</h1>
                      <h1 className="fs-6 mb-0">
                        {(result.similarity_score * 100).toPrecision(2)}%
                      </h1>
                    </div>
                    <p className="text-muted mb-0">
                      At {formatAnswerTime(result.start)}
                    </p>
                    <div className="separator border-secondary my-2"></div>
                    <p
                      className={`fs-7 result-text mb-0 ${
                        width > 576 ? "rt-height" : "max-width-result"
                      }`}
                    >
                      {result.text}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SearchResults;
