import { FC, useEffect, useState } from "react";
import { EventStatus, QuestionModel } from "../core/_models";
import { Color } from "../../../../_metronic/partials/notification/Notification";
import { useAuth, UserModel } from "../../../modules/auth";
import { deleteQuestion, updateQuestion, upvoteQuestion } from "../core/_requests";
import { calculateTime, formatQuestionDate } from "../core/_helpers";
import ReviewInfoPopover from "../../../../_metronic/partials/popover/ReviewInfoPopover";
import { useThemeMode } from "../../../../_metronic/partials";

interface QuestionProps {
  currentUser?: UserModel;
  question: QuestionModel;
  userParticipation: boolean;
  isInsideModal: boolean;
  upvotedQuestion?: QuestionModel;
  eventStatus: EventStatus;
  qna_closed?: boolean;
  isModerated: boolean;
  embedded?: boolean;
  handleDeleteQuestion: (questionId: number) => void;
  onQuestionUpdated: (question: QuestionModel) => void;
  createNotification: (color: Color, message: string) => void;
  onAnswerClick?: (question: QuestionModel) => void;
  onUpvoteNotSignedIn: (questionId: number) => void;
}

const Question: FC<QuestionProps> = ({
  question,
  currentUser,
  createNotification,
  onAnswerClick,
  userParticipation,
  isInsideModal,
  eventStatus,
  qna_closed,
  onUpvoteNotSignedIn,
  upvotedQuestion,
  isModerated,
  handleDeleteQuestion,
  onQuestionUpdated,
  embedded
}) => {
  const { customTextColor } = useThemeMode();
  const { key } = useAuth();
  const [upvote, setUpvote] = useState<boolean>(question.user_upvote);
  const [isEditing, setIsEditing] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState(question.question_body);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [questionState, setQuestionState] = useState<QuestionModel>(question);

  useEffect(() => {setQuestionState(question)}, [question]);

  useEffect(() => {
    if (upvotedQuestion && upvotedQuestion.id == question.id)
      setQuestionState(upvotedQuestion);
  }, [upvotedQuestion]);

  const handleUpvote = async () => {
    if (!currentUser) {
      createNotification(
        "info",
        "Please create an account or log into an existing one to upvote this question."
      );
      return;
    }

    if (!userParticipation) {
      onUpvoteNotSignedIn(question.id);
      return;
    }

    if (question.id == undefined) {
      createNotification(
        "error",
        "Something went wrong please try again later."
      );
      return;
    }
    //Save old state to revert back if the endpoint call fails
    var oldQuestionState = { ...questionState };
    //Update the state before the endpoint call to achieve more snappy UI
    var newQuestionState = { ...questionState };
    newQuestionState.user_upvote = !newQuestionState.user_upvote;
    newQuestionState.upvotes_count = newQuestionState.user_upvote
      ? newQuestionState.upvotes_count + 1
      : newQuestionState.upvotes_count - 1;

    setQuestionState(newQuestionState);

    try {
      var response = await upvoteQuestion(question.id, key);
      if (response.status != 200) throw new Error("Failed to upvote question");
      setQuestionState(response.data);
      onQuestionUpdated(response.data as QuestionModel);
    } catch(error){
      //Revert back to old state if the endpoint call fails
      setQuestionState(oldQuestionState);
      createNotification(
        "error",
        "Something went wrong please try again later."
      );
    }
  };

  const handleEditQuestion = () => {
    setTextAreaValue(question.question_body);
    setIsEditing(!isEditing);
  };

  const handleSaveQuestion = async () => {
    if (textAreaValue.trim().length == 0) {
      createNotification("error", "Question cannot be empty");
      setIsValid(false);
      return;
    }

    try{
      await updateQuestion(questionState.id, textAreaValue, questionState.is_anonymous, key);
      createNotification("success", "Question deleted successfully!");
      setQuestionState({...questionState, question_body: textAreaValue});
      setIsEditing(false);
    }catch(error){
      createNotification("error", "Failed to update question. Try again later.");
    }
  };

  const handleClickDeleteQuestion = async () => {
    try{
      await deleteQuestion(questionState.id, key);
      createNotification("success", "Question deleted successfully!");
      handleDeleteQuestion(questionState.id);
    }catch(error){
      createNotification("error", "Failed to delete question. Try again later.");
    }
    
  };

  return (
    <>
      {questionState.question_body.length !== 0 && (
        <div className={`card card-custom ${questionState.status !== "D" && "mt-5"} ${embedded ? " border-0 rounded-0" : ""}`}>
          <div className="card-header question-header-custom">
            <div className="d-flex mt-5">
              <div className="symbol display-center-mobile symbol-40px me-5">
                <div className="symbol-label fs-3 fw-bold bg-black text-inverse-danger">
                  {questionState.author_name.charAt(0).toUpperCase()}
                </div>
              </div>
              <div className="flex-column">
                <p className="name mb-1 fw-bold">
                  {questionState.can_edit
                    ? `${questionState.author_name + "\u00A0" + "(You)"}`
                    : questionState.author_name}
                </p>
                <div className="d-flex d-flex-column-mobile">
                  {" "}
                  <p className="fs-7 text-muted no-margin-mobile">
                    {formatQuestionDate(questionState.posted_on)}
                  </p>
                  {isModerated &&
                    question.can_edit == true &&
                    questionState.status &&
                    (questionState.status == "D" ||
                      questionState.status == "P") && (
                      <div className="d-flex d-flex-column-385px">
                        <p className="mb-0 margin-right-mobile text-muted ms-3 no-margin-mobile">
                          Review status:
                        </p>
                        <div className="d-flex">
                          <div
                            className={`badge badge-sm ms-2 me-2 ${
                              questionState.status &&
                              questionState.status == "D" &&
                              "badge-danger"
                            } ${
                              questionState.status &&
                              questionState.status == "P" &&
                              "badge-warning"
                            }`}
                          >
                            {(questionState.status &&
                              questionState.status == "P" &&
                              "Pending") ||
                              (questionState.status &&
                                questionState.status == "D" &&
                                "Declined")}
                          </div>
                          <ReviewInfoPopover
                            question_status={questionState.status}
                            position="bottom"
                          ></ReviewInfoPopover>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="card-toolbar">
              {isInsideModal && (
                <p className="mb-0 me-2 fs-7 text-muted">
                  {calculateTime(questionState.posted_on)}
                </p>
              )}
              {questionState.asked_live && (
                <span className="badge badge-danger fs-7">Asked live</span>
              )}
              {questionState.can_edit &&
                (eventStatus !== "ARCHIVED" || qna_closed == false) &&
                questionState.status !== "D" && (
                  <>
                    <a
                      className="btn btn-active-secondary btn-icon edit-question-btn"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="ki-outline ki-dots-vertical fs-1" />
                    </a>
                    <ul className="dropdown-menu fs-5">
                      <li>
                        <a
                          className="dropdown-item d-flex flex-start hover-poiter"
                          onClick={() => {
                            handleEditQuestion();
                          }}
                        >
                          <i className="ki-outline ki-notepad-edit  me-2 fs-5 mt-1"></i>
                          Edit question
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item d-flex flex-start hover-danger hover-poiter"
                          onClick={() => {
                            handleClickDeleteQuestion();
                          }}
                        >
                          <i className="ki-outline ki-cross-circle  me-2 fs-5 pt-1"></i>
                          Delete question
                        </a>
                      </li>
                    </ul>
                  </>
                )}
            </div>
          </div>
          <div className="card-body">
            {isEditing ? (
              <div className="d-flex align-items-center">
                <textarea
                  value={textAreaValue}
                  className={`form-control ${isValid ? "" : "is-invalid"}`}
                  onChange={(e) => {
                    setTextAreaValue(e.currentTarget.value);
                    setIsValid(true);
                  }}
                  onBlur={(e) => {
                    e.currentTarget.value.trim().length == 0
                      ? setIsEditing(false)
                      : null;
                  }}
                ></textarea>
                <a
                  className="btn btn-success btn-sm ms-10 me-5"
                  onClick={handleSaveQuestion}
                >
                  Save
                </a>
                <a
                  className="btn btn-secondary btn-sm "
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  Close
                </a>
              </div>
            ) : (
              questionState.question_body
            )}
          </div>
          <div className={"card-footer p-0 d-flex"}>
            <a
              onClick={handleUpvote}
              className={`btn btn-active-secondary w-50 text-hover-primary ${
                questionState.user_upvote ? "text-primary" : ""
              }`}
            >
              <i
                className={`ki-outline ki-like fs-3 fw-bold mb-1 text-hover-primary ${
                  questionState.user_upvote ? "text-primary" : ""
                }`}
              ></i>
              Upvote
            </a>
            <div className="w-50 d-flex flex-center">
              <p className="fs-5 fw-medium mt-0 mb-0">
                {questionState.upvotes_count}
                {questionState.upvotes_count == 1 ? " upvote" : " upvotes"}
              </p>
            </div>
          </div>
          {!isInsideModal && questionState.answers.length > 0 && (
            <div
              onClick={() => onAnswerClick!(questionState)}
              className={`card-footer footer-answer p-5 d-flex justify-content-between ${embedded ? "border-0 rounded-0" : ""}`}
            >
              <div className="d-flex">
                <i className="footer-icon ki-outline ki-verify fs-2 pe-2 align-self-center"></i>
                <p className="m-0 pe-3">Answered by</p>
                <p className="pe-2 fw-bold m-0">
                  {questionState.answers.map((answer, index) => {
                    if (answer.answered_by) {
                      return (
                        answer.answered_by +
                        (index != questionState.answers.length - 1
                          ? "," + "\u00A0"
                          : "")
                      );
                    }
                    if (
                      (answer.answered_by == null || answer.answered_by == undefined) &&
                      answer.answered_by_name
                    ) {
                      return (
                        answer.answered_by_name +
                        (index != questionState.answers.length - 1
                          ? "," + "\u00A0"
                          : "")
                      );
                    }
                    if (
                      (answer.answered_by == null || answer.answered_by == undefined) &&
                      answer.author_name
                    ) {
                      return (
                        answer.author_name +
                        (index != questionState.answers.length - 1
                          ? "," + "\u00A0"
                          : "")
                      );
                    }
                  })}
                </p>
              </div>
              <div className="see-answer-wrapper d-flex m-0">
                <p className="m-0 pe-2">See answer</p>
                <i className="footer-icon ki-outline ki-exit-right-corner fs-3 align-self-center"></i>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Question;
