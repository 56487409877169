import axios from "axios";
import {
  FilterOption,
  InvestorDetailsModel,
  SortOption,
  LanguageModel,
} from "./_models";

const API_URL = import.meta.env.VITE_APP_API_URL_BASE;

export function getEventByIdWithKey(eventId: string, key?: string) {
  const config: {
    headers: { [key: string]: string };
  } = {
    headers: {},
  };

  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }
  
  return axios.get(`${API_URL}/events/detail/${eventId}/`, config);
}

export function getEventById(eventId: string) {
  const config: {
    withCredentials: true;
  } = {
    withCredentials: true,
  };

  return axios.get(`${API_URL}/events/detail/${eventId}/`, config);
}

export function getEventsByCompanyId(company?: string, key?: string) {
  const config: {
    withCredentials: true;
    headers: { [key: string]: string };
  } = {
    withCredentials: true,
    headers: {},
  };

  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  return axios.get(`${API_URL}/company/${company}/events/`, config);
}

export function getQuestionsByEventId(eventId: string, key?: string) {
  let headers = {} as any;

  if (key) {
    headers["Authorization"] = `Token ${key}`;
  }

  return axios.get(`${API_URL}/questions/event/${eventId}/`, {
    withCredentials: true,
    headers: headers,
  });
}

export function getAnswersByEventId(questionId: string, key?: string) {
  let headers = {} as any;

  if (key) {
    headers["Authorization"] = `Token ${key}`;
  }

  return axios.get(`${API_URL}/questions/${questionId}/answers/`, {
    withCredentials: true,
  });
}

export function postQuestion(
  eventId: string,
  question_body: string,
  is_anonymous: boolean,
  author_email?: string,
  key?: string
) {
  var csrf = getCookie("csrftoken");

  let headers = {
    "X-CSRFToken": csrf,
  } as any;

  if (key) {
    headers["Authorization"] = `Token ${key}`;
  }

  return axios.post(
    `${API_URL}/questions/event/${eventId}/`,
    {
      question_body: question_body,
      author_email: author_email,
      is_anonymous: is_anonymous,
    },
    {
      headers: headers,
      withCredentials: true,
    }
  );
}

export function getTranscriptWithSentiment(
  eventId: string,
  hasTranslations: boolean,
  requestedLanguage?: LanguageModel,
  key?: string
) {
  const config: {
    withCredentials: true;
    headers: { [key: string]: string };
  } = {
    withCredentials: true,
    headers: {},
  };

  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  if (hasTranslations && requestedLanguage) {
    if (requestedLanguage.code === null) {
      return axios.get(`${API_URL}/events/${eventId}/transcript/`, config);
    } else {
      return axios.get(
        `${API_URL}/events/${eventId}/transcript/${requestedLanguage.code}/`, config
      );
    }
  } else {
    return axios.get(`${API_URL}/events/${eventId}/transcript/`, config);
  }
}

export function getQuestionsBySearch(eventId: string, searchQuery: string, key?: string) {
  let headers = {
  } as any;

  if (key) {
    headers["Authorization"] = `Token ${key}`;
  }

  return axios.get(`${API_URL}/questions/event/${eventId}/`, {
    params: {
      query: searchQuery,
    },
    withCredentials: true,
    headers: headers,
  });
}

export function checkIfUserSignedUp(email: string) {
  var csrf = getCookie("csrftoken");
  return axios.post(
    `${API_URL}/auth/check-email/`,
    { email: email },
    {
      headers: {
        "X-CSRFToken": csrf,
      },
      withCredentials: true,
    }
  );
}

export function updateQuestion(
  questionId: number,
  questionBody: string,
  isAnonymous: boolean,
  key?: string
) {
  var csrf = getCookie("csrftoken");

  let headers = {
    "X-CSRFToken": csrf,
  } as any;

  if (key) {
    headers["Authorization"] = `Token ${key}`;
  }

  return axios.put(
    `${API_URL}/questions/${questionId}/`,
    { question_body: questionBody, is_anonymous: isAnonymous },
    {
      headers: headers,
      withCredentials: true,
    }
  );
}

export function upvoteQuestion(questionId: number, key?: string) {
  var csrf = getCookie("csrftoken");

  let headers = {
    "X-CSRFToken": csrf,
  } as any;

  if (key) {
    headers["Authorization"] = `Token ${key}`;
  }

  return axios.post(
    `${API_URL}/questions/upvote/`,
    { question_id: questionId },
    {
      headers: headers,
      withCredentials: true,
    }
  );
}

export function deleteQuestion(questionId: number, key?: string) {
  var csrf = getCookie("csrftoken");

  let headers = {
    "X-CSRFToken": csrf,
  } as any;

  if (key) {
    headers["Authorization"] = `Token ${key}`;
  }

  return axios.delete(`${API_URL}/questions/${questionId}/`, {
    headers: headers,
    withCredentials: true,
  });
}

export function getSemanticSearchResults(
  eventId: string,
  searchQuery: string,
  language?: LanguageModel,
  key?: string
) {
  const config: {
    withCredentials: true;
    headers: { [key: string]: string };
  } = {
    withCredentials: true,
    headers: {},
  };

  if (key) {
    config.headers["Authorization"] = `Token ${key}`;
  }

  if (language == undefined) {
    return axios.get(`${API_URL}/search/event/${eventId}/?q=${searchQuery}/`, config);
  } else if (language.code == undefined || language.code == null) {
    return axios.get(`${API_URL}/search/event/${eventId}/?q=${searchQuery}/`, config);
  } else {
    return axios.get(
      `${API_URL}/search/event/${eventId}/?q=${searchQuery}&language=${language.code}`, config
    );
  }
}

export function participateInEvent(
  eventId: number,
  investorDetails: InvestorDetailsModel,
  key?: string
) {
  var csrf = getCookie("csrftoken");

  let headers = {
    "X-CSRFToken": csrf,
  } as any;

  if (key) {
    headers["Authorization"] = `Token ${key}`;
  }

  return axios.post(
    `${API_URL}/events/participate/`,
    {
      event_id: eventId,
      owns_shares: investorDetails.owns_shares,
      contact_consent: investorDetails.contact_consent,
    },
    {
      headers: headers,
      withCredentials: true,
    }
  );
}

export function leaveEvent(eventId: number) {
  var csrf = getCookie("csrftoken");
  return axios.post(
    `${API_URL}/events/leave/`,
    {
      event_id: eventId,
    },
    {
      headers: {
        "X-CSRFToken": csrf,
      },
      withCredentials: true,
    }
  );
}

export function getQuestionsBySort(eventId: string, sortOption: SortOption) {
  return axios.get(`${API_URL}/questions/event/${eventId}/`, {
    params: {
      sort: sortOption,
    },
    withCredentials: true,
  });
}

export function getQuestionsByFilter(
  eventId: string,
  filterOption: FilterOption
) {
  return axios.get(`${API_URL}/questions/event/${eventId}/`, {
    params: {
      filter: filterOption,
    },
    withCredentials: true,
  });
}

function getCookie(name: string) {
  const value = `${document.cookie}`;
  const parts = value.split(`${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
}
