import { FC } from "react";
import {
  Popover as BootstrapPopover,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import { applyHighlight, applySentimentForPopup } from "../../../app/pages/event/core/_helpers";
import { useThemeMode } from "..";

interface HighlightPopoverProps {
  hasSentiment: boolean;
  wasSearched: boolean;
  hasTags: boolean;
  embedded?: boolean;
  position: "top" | "right" | "bottom" | "left" | "auto" | undefined;
}

const HighlightPopover: FC<HighlightPopoverProps> = ({
  hasSentiment,
  position,
  wasSearched,
  hasTags,
  embedded
}) => {
  const { mode } = useThemeMode();
  const sentimentPopover = (
    <BootstrapPopover id="popover-basic" style={{zIndex: embedded ? 9999 : 2}}>
      <BootstrapPopover.Header as="h1" className="fs-3 fw-bold">
        Highlights legend
      </BootstrapPopover.Header>
      <BootstrapPopover.Body>
        {hasSentiment && (
          <>
            {" "}
            <h5 className="">Sentiment highlight</h5>
            <p className="mb-1">
              When this option is selected, sentences with positive and negative
              sentiment will be highlighted.
            </p>
            <div className="examples mb-5">
              <p className="mb-1 fw-bold">Example:</p>
              <p
                className="sentence-example mb-1 me-1"
                style={{
                  backgroundColor: applySentimentForPopup(mode, "positive"),
                }}
              >
                This sentence is positive.
              </p>
              <p
                className="sentence-example ps-1 pe-1"
                style={{
                  backgroundColor: applySentimentForPopup(mode, "negative"),
                }}
              >
                This sentence is negative.
              </p>
              <p className="sentence-example ps-1">This sentence is neutral.</p>
            </div>
          </>
        )}
        {wasSearched && (
          <>
            <h5 className="">Search highlight</h5>
            <p className="mb-1">
              When this option is selected, search results are highlighted.
            </p>
            <div className="examples mb-5">
            <p className="mb-1 fw-bold">Example:</p>
              <p
                className="sentence-example mb-1 me-1"
                style={{
                  backgroundColor: "#ffa845",
                }}
              >
                Focused search result.
              </p>
              <p
                className="sentence-example mb-1 pe-1"
                style={{
                  backgroundColor: "#f8df50",
                }}
              >
                Other search results.
              </p>
            </div>
          </>
        )}
        {hasTags && (
          <>
            <h5 className="">Tag highlight</h5>
            <p className="mb-5">
              When this option is selected, tags are highlighted. Tags can have variety of colors and are used to mark different types of information.
            </p>
          </>
        )}
      </BootstrapPopover.Body>
    </BootstrapPopover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement={position}
      overlay={sentimentPopover}
      rootClose={true}
    >
      <i className="ki-outline ki-question-2 fs-3 me-3 hover-active w-min" style={{height: embedded ? "min-content" : "", alignSelf: embedded ? "center" : ""}}/>
    </OverlayTrigger>
  );
};

export default HighlightPopover;
