import { FC, useEffect, useState } from "react";
import { useThemeMode } from "../../../../_metronic/partials";

interface FloatingControlsProps {
  tagsBar: boolean;
  hasTags: boolean;
  closeTagsBar: () => void;
  openTagsBar: () => void;
}

const FloatingControls: FC<FloatingControlsProps> = ({
  tagsBar,
  openTagsBar,
  closeTagsBar,
  hasTags,
}) => {
  const { customBtnColor, customTextColor } = useThemeMode();
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    const editorScrollContainer = document.querySelector(".transcript-editor");
    const eventScrollContainer = document.querySelector(".scrollable");
    const handleScroll = () => {
      if (
        (editorScrollContainer && editorScrollContainer.scrollTop === 0) ||
        (eventScrollContainer && eventScrollContainer.scrollTop === 0)
      ) {
        setIsTop(true);
      } else {
        setIsTop(false);
      }
    };

    editorScrollContainer?.addEventListener("scroll", handleScroll);
    eventScrollContainer?.addEventListener("scroll", handleScroll);
    return () => {
      editorScrollContainer?.removeEventListener("scroll", handleScroll);
      eventScrollContainer?.addEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollTop = () => {
    const editorScrollContainer = document.querySelector(".transcript-editor");
    const eventScrollContainer = document.querySelector(".scrollable");

    if (editorScrollContainer) {
      editorScrollContainer.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    if (eventScrollContainer) {
      eventScrollContainer.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="floating-controls hide-mobile">
      {!isTop && (
        <a
          className={`btn btn-sm btn-icon btn-opacity-50 ${
            customBtnColor ? "btn-iframe-custom" : "btn-primary"
          }`}
          style={customBtnColor ? { "--custom-btn-color": customBtnColor } : {}}
          onClick={scrollTop}
        >
          <i
            className={`ki-outline ki-arrow-up ${
              customTextColor ? customTextColor : "text-white"
            }`}
          ></i>
        </a>
      )}
      {hasTags && (
        <>
          {tagsBar ? (
            <a
              className={`btn btn-sm btn-icon btn-opacity-50 ms-3 ${
                customBtnColor ? "btn-iframe-custom" : "btn-primary"
              }`}
              style={
                customBtnColor ? { "--custom-btn-color": customBtnColor } : {}
              }
              onClick={closeTagsBar}
            >
              <i
                className={`ki-outline ki-arrow-right ${
                  customTextColor ? customTextColor : "text-white"
                }`}
              ></i>
            </a>
          ) : (
            <a
              className={`btn btn-sm btn-icon btn-opacity-50 ms-3 ${
                customBtnColor ? "btn-iframe-custom" : "btn-primary"
              }`}
              style={
                customBtnColor ? { "--custom-btn-color": customBtnColor } : {}
              }
              onClick={openTagsBar}
            >
              <i
                className={`ki-outline ki-arrow-left ${
                  customTextColor ? customTextColor : "text-white"
                }`}
              ></i>
            </a>
          )}
        </>
      )}
    </div>
  );
};

export default FloatingControls;
