import { FC } from "react";
import { Popover as BootstrapPopover, OverlayTrigger, Button } from 'react-bootstrap';
import { applySentimentForPopup } from "../../../app/pages/event/core/_helpers";
import { useThemeMode } from "..";

interface ReviewInfoPopoverProps {
  question_status: "P" | "D";
  position: "top" | "right" | "bottom" | "left" | "auto" | undefined;
}

const ReviewInfoPopover: FC<ReviewInfoPopoverProps> = ({question_status, position}) => {
  const { mode } = useThemeMode();
  const reviewInfoPopover = (
    <BootstrapPopover id="popover-basic">
      <BootstrapPopover.Header as="h2">Question review</BootstrapPopover.Header>
      <BootstrapPopover.Body>
        <div className="mb-0">
        {question_status === "P" ? "This question is being reviewed by the company and only you can see it." : "This question was declined by the company and only you can see it."} 
        </div>
      </BootstrapPopover.Body>
    </BootstrapPopover>
  );

  return (
    <OverlayTrigger trigger="click" placement={position} overlay={reviewInfoPopover}>
      <i className="ki-outline ki-information-2 fs-4 mt-1 hover-active review-info"/>
    </OverlayTrigger>
  );
};

export default ReviewInfoPopover;