import { useEffect } from "react";
import { useLocation } from "react-router";
import clsx from "clsx";
import { useLayout } from "../../core";
import { DrawerComponent } from "../../../assets/ts/components";
import { WithChildren } from "../../../helpers";

const Content = ({ children }: WithChildren) => {
  const { config, classes } = useLayout();
  const location = useLocation();
  const currentPage = location.pathname;

  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  const appContentContainer = config.app?.content?.container;
  return (
    <div
      id="kt_app_content"
      className={clsx(
        "app-content flex-column-fluid",
        classes.content.join(" "),
        config?.app?.content?.class,
        {
          "no-padding":
            currentPage.startsWith("/app/") ||
            currentPage.startsWith("/error/") ||
            currentPage.startsWith("/dashboard/"),
        }
      )}
    >
      {appContentContainer ? (
        <div
          id="kt_app_content_container"
          className={clsx("app-container", classes.contentContainer.join(" "), {
            "container-xxl": appContentContainer === "fixed",
            "container-fluid": appContentContainer === "fluid",
            "app-container-fit-desktop": currentPage.startsWith("/app/") ||
            currentPage.startsWith("/error/"),
            "no-padding-right": currentPage.startsWith("/dashboard/"),
            "no-padding-left": currentPage.endsWith("/widget/edit"),
          })}
        >
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export { Content };
