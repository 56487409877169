import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import companyEvents from "./temp-data/companyEvents.json";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../modules/auth";
import { CompanyEventModel } from "./core/_models";
import { useThemeMode } from "../../../_metronic/partials";
import { formatDate } from "./core/_helpers";
import { getEventsByCompanyId } from "./core/_requests";

interface EventListEmbeddedProps {}

const getData = async (company?: string, key?: string) => {
  const request = await getEventsByCompanyId(company, key);
  return request.data;
};

const staticUrl = import.meta.env.VITE_APP_STATIC_URL;

const EventListEmbedded: FC<EventListEmbeddedProps> = () => {
  const { key } = useAuth();
  const { customBtnColor, customTextColor } = useThemeMode();
  const { mode } = useThemeMode();
  const { company } = useParams<{ company: string }>();
  const [events, setEvents] = useState<CompanyEventModel[]>([]);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

  const navigate = useNavigate();

  const {
    data: fetchedEvents,
    refetch: refetchEvents,
    isError,
  } = useQuery(["event", company], () => getData(company, key), {
    suspense: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 5 * 60 * 1000,
  });

  useLayoutEffect(() => {
    const checkScrollable = () => {
      if (scrollContainerRef.current) {
        const { scrollWidth, clientWidth, scrollLeft } =
          scrollContainerRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 1);
      }
    };

    checkScrollable();

    scrollContainerRef.current?.addEventListener("scroll", checkScrollable);
    window.addEventListener("resize", checkScrollable);
    return () => {
      scrollContainerRef.current?.removeEventListener(
        "scroll",
        checkScrollable
      );
      window.removeEventListener("resize", checkScrollable);
    };
  }, [scrollContainerRef.current, events]);

  useEffect(() => {
    if (fetchedEvents) {
      setEvents(fetchedEvents);
    }
  }, [fetchedEvents]);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  return (
    <div
      className="d-flex flex-column p-0"
      style={{ height: "calc(100% - 30px)" }}
    >
      <div
        style={{ zIndex: 9999 }}
        className={`position-fixed top-0 d-flex justify-content-center w-100 bg-base p-3 ${
          mode == "light" ? "bg-light" : "bg-dark"
        }`}
      >
        <h2 className="m-0">Events</h2>
      </div>
      {events.length > 0 ? (
        <div
          ref={scrollContainerRef}
          className={`w-100 d-flex flex-row overflow-auto h-100 gap-5 pe-5 ps-5 align-items-center ${
            events.length === 1
              ? "justify-content-center"
              : "justify-content-start"
          }`}
          style={{ paddingTop: 60 }}
        >
          {canScrollLeft && (
            <button
              className={`btn btn-icon ${
                customBtnColor
                  ? "btn-iframe-custom " + customTextColor
                  : "btn-primary "
              }`}
              onClick={scrollLeft}
              style={
                customBtnColor
                  ? {
                      "--custom-btn-color": customBtnColor,
                      zIndex: 10,
                      position: "absolute",
                      left: 10,
                      transform: "translateY(-50%)",
                      boxShadow:
                        mode === "light"
                          ? "0 2px 5px rgba(0, 0, 0, 0.5)"
                          : "0 2px 5px rgba(255, 255, 255, 0.5)",
                    }
                  : {
                      zIndex: 10,
                      position: "absolute",
                      left: 10,
                      transform: "translateY(-50%)",
                      boxShadow:
                        mode === "light"
                          ? "0 2px 5px rgba(0, 0, 0, 0.5)"
                          : "0 2px 5px rgba(255, 255, 255, 0.5)",
                    }
              }
            >
              <i
                className={`ki-outline ki-left fs-2x ${
                  customBtnColor ? customTextColor : ""
                }`}
              ></i>
            </button>
          )}
          {events.map((event, index) => (
            <div
              key={event.id}
              className=" w-100 card mb-5 p-0 rounded-4 shadow-sm"
              style={{
                minHeight: 260,
                minWidth: 300,
                height: "calc(100vh - 130px)",
                maxWidth: 700,
                maxHeight: 300,
                overflow: "hidden",
              }}
            >
              <div className="d-flex flex-column card-body justify-content-between p-0">
                <div className="d-flex flex-column h-100 p-5 pt-7">
                  <div className="d-flex w-100 justify-content-between">
                    <p
                      className="m-0 d-flex align-items-center"
                      style={{ zIndex: 1 }}
                    >
                      {formatDate(event.start_date)}
                    </p>
                    <span
                      className={`badge ${
                        event.status === "SCHEDULED"
                          ? "badge-success"
                          : event.status === "LIVE"
                          ? "badge-danger"
                          : "badge-secondary"
                      }`}
                    >
                      {event.status}
                    </span>
                  </div>
                  <h3
                    className="m-0 h1 fw-bold d-flex align-items-center"
                    style={{ zIndex: 1 }}
                  >
                    {event.title}
                  </h3>
                  <div className="d-flex mt-5 gap-3" style={{ zIndex: 1 }}>
                    {event.slides_url && (
                      <>
                        <a
                          className={`${
                            mode === "light" ? "text-black" : "text-white"
                          } cursor-pointer d-flex align-items-center`}
                          href={staticUrl + event.slides_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            className={`fa-regular fa-file-pdf fs-1 text-center me-2 ${
                              mode === "light" ? "text-black" : "text-muted"
                            }`}
                          ></i>
                          <span>Slides</span>
                        </a>
                      </>
                    )}
                    &nbsp;
                    {event.report_url && (
                      <>
                        <a
                          className={`${
                            mode === "light" ? "text-black" : "text-white"
                          } cursor-pointer d-flex align-items-center`}
                          href={staticUrl + event.report_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            className={`fa-regular fa-file-pdf fs-1 text-center me-2 ${
                              mode === "light" ? "text-black" : "text-muted"
                            }`}
                          ></i>
                          <span>Report</span>
                        </a>
                      </>
                    )}
                  </div>
                  <div className="h-100 d-flex align-items-end justify-content-end">
                    <p
                      className={`fw-bolder text-gray-400 text-end  display-1`}
                      style={{
                        position: "absolute",
                        transform: "translate(0%, 25%)",
                        zIndex: 0,
                        fontSize:
                          !event.event_type ||
                          (event.event_type && event.event_type.length >= 3)
                            ? "4rem"
                            : "10rem",
                        marginBottom:
                          !event.event_type ||
                          (event.event_type && event.event_type.length >= 3)
                            ? 20
                            : 0,
                      }}
                    >
                      {event.event_type ? event.event_type : "Investor Event"}
                    </p>
                  </div>
                </div>
                {event.status === "SCHEDULED" && (
                  <div className="d-flex">
                    <a
                      className={`btn w-50 ${
                        customBtnColor
                          ? "btn-iframe-custom " + customTextColor
                          : "btn-primary "
                      }`}
                      onClick={() =>
                        navigate(`/app/event/embedded/${event.id}`)
                      }
                      style={
                        customBtnColor
                          ? {
                              "--custom-btn-color": customBtnColor,
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              marginRight: 1,
                              zIndex: 1,
                            }
                          : {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              marginRight: 1,
                              zIndex: 1,
                            }
                      }
                    >
                      Sign up
                    </a>
                    <a
                      className={`btn w-50 ${
                        customBtnColor
                          ? "btn-iframe-custom " + customTextColor
                          : "btn-primary "
                      }`}
                      onClick={() =>
                        navigate(`/app/event/embedded/${event.id}?action=showQuestions`)
                      }
                      style={
                        customBtnColor
                          ? {
                              "--custom-btn-color": customBtnColor,
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              marginLeft: 1,
                              zIndex: 1,
                            }
                          : {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              marginLeft: 1,
                              zIndex: 1,
                            }
                      }
                    >
                      Ask questions
                    </a>
                  </div>
                )}
                {event.status === "LIVE" && (
                  <a
                    className={`btn rounded-bottom-4 ${
                      customBtnColor
                        ? "btn-iframe-custom " + customTextColor
                        : "btn-primary "
                    }`}
                    onClick={() => navigate(`/app/event/embedded/${event.id}`)}
                    style={
                      customBtnColor
                        ? {
                            "--custom-btn-color": customBtnColor,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            zIndex: 1,
                          }
                        : {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            zIndex: 1,
                          }
                    }
                  >
                    Webcast
                  </a>
                )}
                {event.status === "ARCHIVED" && (
                  <a
                    className={`btn rounded-bottom-4 ${
                      customBtnColor
                        ? "btn-iframe-custom " + customTextColor
                        : "btn-primary "
                    }`}
                    onClick={() => navigate(`/app/event/embedded/${event.id}`)}
                    style={
                      customBtnColor
                        ? {
                            "--custom-btn-color": customBtnColor,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            zIndex: 1,
                          }
                        : {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            zIndex: 1,
                          }
                    }
                  >
                    Watch recording
                  </a>
                )}
              </div>
            </div>
          ))}
          {canScrollRight && (
            <button
              className={`btn btn-icon ${
                customBtnColor
                  ? "btn-iframe-custom " + customTextColor
                  : "btn-primary "
              }`}
              onClick={scrollRight}
              style={
                customBtnColor
                  ? {
                      "--custom-btn-color": customBtnColor,
                      zIndex: 10,
                      position: "absolute",
                      right: 10,
                      transform: "translateY(-50%)",
                      boxShadow:
                        mode === "light"
                          ? "0 2px 5px rgba(0, 0, 0, 0.5)"
                          : "0 2px 5px rgba(255, 255, 255, 0.5)",
                    }
                  : {
                      zIndex: 10,
                      position: "absolute",
                      right: 10,
                      transform: "translateY(-50%)",
                      boxShadow:
                        mode === "light"
                          ? "0 2px 5px rgba(0, 0, 0, 0.5)"
                          : "0 2px 5px rgba(255, 255, 255, 0.5)",
                    }
              }
            >
              <i
                className={`ki-outline ki-right fs-2x ${
                  customBtnColor ? customTextColor : ""
                }`}
              ></i>
            </button>
          )}
        </div>
      ) : (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <h1 className="fs-2x text-muted">No events found.</h1>
        </div>
      )}
    </div>
  );
};

export { EventListEmbedded };
