import { FC, useEffect, useRef, useState } from "react";
import { Player } from "./components/Player";

import EventTranscriptModal from "../../../../_metronic/partials/modals/event/EventTranscriptModal";
import { updateHeaderTitle } from "../core/_helpers";
import { Color } from "../../../../_metronic/partials/notification/Notification";
import { LanguageModel, SpeakerModel } from "../core/_models";
import TranscriptWrapper from "./components/transcript/TranscriptWrapper";
import TranscriptEmbedded from "./components/transcript/TranscriptEmbedded";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useThemeMode } from "../../../../_metronic/partials";

interface EventEndedProps {
  eventId: string;
  eventTitle: string;
  startDate: string;
  company: string;
  videoUrl?: string;
  transcriptPdf?: string;
  transcriptSentimentPdf?: string;
  linkToSlides?: string;
  linkToReport?: string;
  hasTranscript?: boolean;
  availableLanguages?: LanguageModel[];
  speakers?: SpeakerModel[];
  embedded?: boolean;
  tab?: string;
  createNotification: (color: Color, message: string) => void;
}

const EventEnded: FC<EventEndedProps> = ({
  eventTitle,
  startDate,
  eventId,
  company,
  videoUrl,
  createNotification,
  linkToSlides,
  linkToReport,
  availableLanguages,
  transcriptPdf,
  hasTranscript,
  transcriptSentimentPdf,
  embedded,
  tab,
  speakers,
}) => {
  const { customBtnColor, customTextColor } = useThemeMode();
  const [newPlayerTime, setNewPlayerTime] = useState<number>(0);
  const playerTimeRef = useRef(0);
  const resizeBar = useRef<HTMLDivElement | null>(null);
  const resizePill = useRef<HTMLDivElement | null>(null);
  const resizePillLines = useRef<(HTMLDivElement | null)[]>([]);
  const dragging = useRef<boolean>(false);
  const [startX, setStartX] = useState(0);
  const [startLeft, setStartLeft] = useState(0);
  const videoWrapper = useRef<HTMLDivElement | null>(null);
  const transcriptWrapper = useRef<HTMLDivElement | null>(null);
  const [videoResizeBreakPoint, setVideoResizeBreakPoint] = useState(false);
  const [seekedTime, setSeekedtime] = useState<number>(0);
  const [showTranscriptModal, setShowTranscriptModal] = useState(false);
  const [videoLength, setVideoLength] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoPlaying = useRef(false);
  const bannerObserver = useRef<HTMLDivElement | null>(null);
  const [transcriptExists, setTranscriptExists] = useState(hasTranscript);

  const scrollToQuestions = () => {
    const scrollToQuestions = document.getElementById("sort-select-questions");
    if (scrollToQuestions) {
      scrollToQuestions.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    isPlaying ? (videoPlaying.current = true) : (videoPlaying.current = false);
  }, [isPlaying]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          updateHeaderTitle(false);
        } else {
          updateHeaderTitle(true);
        }
      },
      {
        root: null,
        rootMargin: "-80px",
        threshold: 0.1,
      }
    );

    if (bannerObserver.current) {
      observer.observe(bannerObserver.current);
    }

    return () => {
      if (bannerObserver.current) {
        observer.unobserve(bannerObserver.current);
      }
    };
  }, []);

  const displayTranscript = () => {
    setShowTranscriptModal(
      (prevShowTranscriptModal) => !prevShowTranscriptModal
    );
  };

  const seekTime = (time: number) => {
    setNewPlayerTime(Number(time) / 1000);
  };

  const updateTime = (currentTime: number) => {
    playerTimeRef.current = currentTime;
  };

  const resetView = () => {
    if (!embedded && transcriptExists) {
      if (resizeBar.current) {
        if (window.innerWidth <= 992) {
          resizeBar.current!.style.display = "none";
        } else {
          resizeBar.current!.style.display = "block";
        }
      }
      var width = window.innerWidth * 0.5;

      if (videoWrapper.current) {
        videoWrapper.current.style.width = `${width}px`;
      }

      if (transcriptWrapper.current) {
        transcriptWrapper.current.style.width = `${width}px`;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleMouseUp);
    window.addEventListener("resize", resetView);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleMouseUp);
      window.removeEventListener("resize", resetView);
    };
  }, []);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!embedded) {
      e.preventDefault();
      if (resizeBar.current)
        resizeBar.current.style.borderColor = "var(--bs-primary)";
      if (resizePill.current)
        resizePill.current.style.backgroundColor = "#69a1e0";
      if (resizePillLines.current[0])
        resizePillLines.current[0].style.backgroundColor = "var(--bs-primary)";
      if (resizePillLines.current[1])
        resizePillLines.current[1].style.backgroundColor = "var(--bs-primary)";

      dragging.current = true;

      setStartX(e.clientX);

      if (resizeBar.current) {
        setStartLeft(resizeBar.current.offsetLeft);
      }
      document.body.style.userSelect = "none";
      setUpdateTags(false);
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    if (!embedded) {
      e.preventDefault();
      dragging.current = true;

      setStartX(e.touches[0].clientX);
      if (resizeBar.current) {
        setStartLeft(resizeBar.current.offsetLeft);
      }
      document.body.style.userSelect = "none";
      setUpdateTags(false);
    }
  };

  const handleMouseUp = () => {
    if (!embedded) {
      if (resizeBar.current)
        resizeBar.current.style.borderColor = "var(--bs-gray-300)";
      if (resizePill.current)
        resizePill.current.style.backgroundColor = "var(--bs-gray-300)";
      if (resizePillLines.current[0])
        resizePillLines.current[0].style.backgroundColor = "var(--bs-gray-500)";
      if (resizePillLines.current[1])
        resizePillLines.current[1].style.backgroundColor = "var(--bs-gray-500)";
      dragging.current = false;
      document.body.style.userSelect = "";
      setUpdateTags(true);
    }
  };

  const [updateTags, setUpdateTags] = useState(false);

  const handleMouseMove = (e: MouseEvent) => {
    if (!embedded) {
      if (dragging.current == true && resizeBar.current) {
        const windowWidth = window.innerWidth;
        const percent = (e.clientX / windowWidth) * 100;
        if (percent < 25 || percent > 55) {
          return;
        }
        const currentX = e.clientX;
        const diffX = currentX - startX;
        resizeBar.current.style.left = startLeft + diffX + "px";
        const newWidth = `calc(${resizeBar.current.style.left} - 34px)`;
        videoWrapper.current!.style.width = newWidth;
        transcriptWrapper.current!.style.width = `calc(100% - ${newWidth})`;

        let style = window.getComputedStyle(videoWrapper.current!);
        let width = parseInt(style.width);

        if (width <= 650) {
          setVideoResizeBreakPoint(true);
        } else {
          setVideoResizeBreakPoint(false);
        }
      }
    }
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (!embedded) {
      if (dragging.current == true && resizeBar.current) {
        const windowWidth = window.innerWidth;
        const percent = (e.touches[0].clientX / windowWidth) * 100;
        if (percent < 25 || percent > 55) {
          return;
        }
        const currentX = e.touches[0].clientX;
        const diffX = currentX - startX;
        resizeBar.current.style.left = startLeft + diffX + "px";
        const newWidth = `calc(${resizeBar.current.style.left} - 34px)`;
        videoWrapper.current!.style.width = newWidth;
        transcriptWrapper.current!.style.width = `calc(100% - ${newWidth})`;

        let style = window.getComputedStyle(videoWrapper.current!);
        let width = parseInt(style.width);

        if (width <= 650) {
          setVideoResizeBreakPoint(true);
        } else {
          setVideoResizeBreakPoint(false);
        }
      }
    }
  };

  useEffect(() => {
    var buttonsWrapper = document.getElementById("buttons-wrapper");
    var buttonsCol = document.getElementsByClassName("btn-column");
    var buttonsWidth = document.getElementsByClassName("btn-width");

    if (videoResizeBreakPoint) {
      if (buttonsCol) {
        for (let i = 0; i < buttonsCol.length; i++) {
          buttonsCol[i].classList.add("w-100");
        }
      }
      if (buttonsWidth) {
        for (let i = 0; i < buttonsWidth.length; i++) {
          buttonsWidth[i].classList.add("w-50");
        }
      }
      if (buttonsWrapper) {
        buttonsWrapper.classList.add("flex-column");
      }
    } else {
      if (buttonsCol) {
        for (let i = 0; i < buttonsCol.length; i++) {
          buttonsCol[i].classList.remove("w-100");
        }
      }
      if (buttonsWidth) {
        for (let i = 0; i < buttonsWidth.length; i++) {
          buttonsWidth[i].classList.remove("w-50");
        }
      }
      if (buttonsWrapper) {
        buttonsWrapper.classList.remove("flex-column");
      }
    }
  }, [videoResizeBreakPoint]);

  const handleVideoLoad = (duration?: number) => {
    if (duration) {
      setVideoLength(duration);
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth <= 650) {
        setVideoResizeBreakPoint(true);
      } else {
        setVideoResizeBreakPoint(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (
      (transcriptExists === false || transcriptExists === undefined) &&
      !embedded
    ) {
      var eventWrapper = document.getElementById("archived-wrapper");
      if (eventWrapper) {
        eventWrapper.style.display = "flex";
        eventWrapper.style.flexDirection = "row";
        eventWrapper.style.justifyContent = "center";
        eventWrapper.style.alignItems = "center";
      }
      if (transcriptWrapper.current) {
        transcriptWrapper.current.style.display = "none";
      }
      if (videoWrapper.current) {
        videoWrapper.current.style.width = "55%";
        videoWrapper.current.style.height = "auto";
      }
    }
  }, [transcriptExists]);

  useEffect(() => {
    hasTranscript ? setTranscriptExists(true) : setTranscriptExists(false);
  }, [hasTranscript]);

  useEffect(() => {
    resizePillLines.current = resizePillLines.current.slice(0, 2);
  }, []);

  return (
    <div
      id="archived-wrapper"
      className={`${
        embedded
          ? "d-flex flex-column align-items-center"
          : "mx-10 event-archived-wrapper"
      }`}
      style={{
        marginTop: embedded ? "32.3px" : "0px",
        height: embedded ? "calc(100vh - 74px)" : "",
      }}
    >
      {embedded ? (
        <div
          className={`${
            tab !== "Transcript"
              ? "w-100 d-flex justify-content-center align-items-center h-100"
              : "d-flex flex-column align-items-center"
          } `}
        >
          {tab == "Transcript" && (
            <div
              className="w-100 h-100 bg-body"
              style={{ zIndex: 99, position: "fixed" }}
            ></div>
          )}
          {
            <div
              ref={videoWrapper}
              className={`${
                embedded
                  ? "d-flex flex-column align-items-center"
                  : "event-ended-initial-width me-5 event-ended-height"
              }`}
              style={{
                visibility: tab == "Transcript" ? "hidden" : "visible",
                height: tab == "Transcript" ? "0px" : "",
                overflow: tab == "Transcript" ? "" : "",
              }}
            >
              <div className="d-flex justify-content-between">
                <div className="title-date-wrapper mt-5 me-5 ms-5 mb-2">
                  <h1 className="display-7" ref={bannerObserver}>
                    {eventTitle}
                  </h1>
                  <h3 className="fw-bold text-danger">{startDate}</h3>
                </div>
                <div className="d-flex align-items-center">
                  {speakers && (
                    <button
                      className={`btn btn-sm me-5 ${
                        customBtnColor
                          ? "btn-iframe-custom " + customTextColor
                          : "btn-primary "
                      }`}
                      style={
                        customBtnColor
                          ? { "--custom-btn-color": customBtnColor }
                          : {}
                      }
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      Event speakers
                    </button>
                  )}
                </div>
              </div>

              {videoUrl ? (
                <>
                  <Player
                    embedded={embedded}
                    videoUrl={videoUrl!}
                    onSeeked={setSeekedtime}
                    eventTitle={eventTitle}
                    onTimeUpdate={updateTime}
                    newPlayerTime={newPlayerTime}
                    onDurationLoaded={handleVideoLoad}
                    isPlaying={isPlaying}
                    onPlay={setIsPlaying}
                  ></Player>
                </>
              ) : (
                <div className="recording-not-available w-100 h-100" style={{minHeight:200}}>
                  <h3 className="text-white">
                    Recording for this event is not available yet.
                  </h3>
                </div>
              )}
              <div
                id="buttons-wrapper"
                className="buttons-wrapper d-flex justify-content-center align-items-center mt-3 w-100 ps-3 pe-3"
              >
                <div id="downloads" className="d-flex downloads gap-3">
                  {(linkToSlides || linkToReport) && (
                    <div id="btn-column" className="btn-column d-flex me-0">
                      {linkToSlides ? (
                        <a
                          href={linkToSlides}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`btn btn-sm btn-height btn-width h-100 d-flex justify-content-center align-items-center 
                            ${
                              customBtnColor
                                ? "btn-iframe-custom " + customTextColor
                                : "btn-primary "
                            } ${linkToReport ? "me-5" : ""}`}
                          style={
                            customBtnColor
                              ? { "--custom-btn-color": customBtnColor }
                              : {}
                          }
                        >
                          Slides
                        </a>
                      ) : (
                        <a className="w-54"></a>
                      )}
                      {linkToReport ? (
                        <a
                          href={linkToReport}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`btn btn-sm btn-height btn-width h-100 d-flex justify-content-center align-items-center me-0  ${
                            customBtnColor
                              ? "btn-iframe-custom " + customTextColor
                              : "btn-primary "
                          }`}
                          style={
                            customBtnColor
                              ? { "--custom-btn-color": customBtnColor }
                              : {}
                          }
                        >
                          Report
                        </a>
                      ) : (
                        <a className="w-54"></a>
                      )}
                    </div>
                  )}

                  <div id="btn-column" className="btn-column d-flex gap-5">
                    {transcriptPdf ? (
                      <a
                        href={transcriptPdf}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`btn btn-sm w-50 btn-height mt-0 btn-width mobile-text-small h-100 d-flex justify-content-center align-items-center ${
                          customBtnColor
                            ? "btn-iframe-custom " + customTextColor
                            : "btn-primary "
                        }`}
                        style={
                          customBtnColor
                            ? { "--custom-btn-color": customBtnColor }
                            : {}
                        }
                      >
                        Transcript PDF
                      </a>
                    ) : (
                      <a className="w-54"></a>
                    )}
                    {transcriptSentimentPdf ? (
                      <a
                        href={transcriptSentimentPdf}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`btn btn-sm btn-height btn-width mt-0 mobile-text-small h-100 d-flex justify-content-center align-items-center 
                          ${
                            customBtnColor
                              ? "btn-iframe-custom " + customTextColor
                              : "btn-primary "
                          }`}
                        style={
                          customBtnColor
                            ? { "--custom-btn-color": customBtnColor }
                            : {}
                        }
                      >
                        Transcript PDF with sentiment
                      </a>
                    ) : (
                      <a className="w-54"></a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          }
          {tab == "Transcript" && transcriptExists && (
            <TranscriptEmbedded
              createNotification={createNotification}
              onFailedToFetchTranscript={() => setTranscriptExists(false)}
              seekedTime={seekedTime}
              width={760}
              availableLanguages={availableLanguages}
              hasTranscript={hasTranscript}
              eventId={eventId}
              isPlayerPlaying={videoPlaying}
              onPlay={setIsPlaying}
              onSeekTime={seekTime}
              videoLength={videoLength}
              company={company}
              title={eventTitle}
              playerTime={playerTimeRef}
              show={true}
              handleClose={() => setShowTranscriptModal((prev) => !prev)}
            ></TranscriptEmbedded>
          )}
          <div
            className="offcanvas offcanvas-end"
            style={{ zIndex: 9999 }}
            tabIndex={-1}
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              <h5 id="offcanvasRightLabel">Event speakers</h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="card-body">
                {speakers &&
                  speakers.length > 0 &&
                  speakers.map((speaker: SpeakerModel) => {
                    return (
                      <div key={speaker.name} className="speaker row">
                        <div className="symbol symbol-50px col-3">
                          <img
                            alt="Speaker avatar"
                            src={
                              speaker.avatar
                                ? speaker.avatar
                                : toAbsoluteUrl("media/avatars/blank.png")
                            }
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.onerror = null;
                              target.src = toAbsoluteUrl(
                                "media/avatars/blank.png"
                              );
                            }}
                            style={{ objectFit: "contain" }}
                          ></img>
                        </div>
                        <div className="col">
                          <h3>{speaker.name}</h3>
                          <p>{speaker.role}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            ref={videoWrapper}
            className={`event-ended-height ${
              embedded ? "w-100" : "event-ended-initial-width"
            }  me-5 `}
          >
            <div className="title-date-wrapper my-5">
              <h1 className="display-6" ref={bannerObserver}>
                {eventTitle}
              </h1>
              <h3 className="fw-bold text-danger d">{startDate}</h3>
            </div>
            {videoUrl ? (
              <Player
                videoUrl={videoUrl!}
                onSeeked={setSeekedtime}
                eventTitle={eventTitle}
                onTimeUpdate={updateTime}
                newPlayerTime={newPlayerTime}
                onDurationLoaded={handleVideoLoad}
                isPlaying={isPlaying}
                onPlay={setIsPlaying}
              ></Player>
            ) : (
              <div className="recording-not-available w-100 h-100" style={{minHeight:300}}>
                <h3 className="text-white">
                  Recording for this event is not available yet.
                </h3>
              </div>
            )}
            <div
              id="buttons-wrapper"
              className="buttons-wrapper d-flex justify-content-between mt-3 gap-5"
            >
              <div id="downloads" className="d-flex gap-5">
                {(linkToSlides || linkToReport) && (
                  <div id="btn-column" className="btn-column d-flex me-0">
                    {linkToSlides ? (
                      <a
                        href={linkToSlides}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`btn btn-sm btn-primary btn-height btn-width h-100 d-flex justify-content-center align-items-center ${
                          linkToReport ? "me-5" : ""
                        }`}
                      >
                        Slides
                      </a>
                    ) : (
                      <a className="w-54"></a>
                    )}
                    {linkToReport ? (
                      <a
                        href={linkToReport}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-sm btn-primary btn-height btn-width h-100 d-flex justify-content-center align-items-center me-0"
                      >
                        Report
                      </a>
                    ) : (
                      <a className="w-54"></a>
                    )}
                  </div>
                )}

                <div id="btn-column" className="btn-column d-flex gap-5">
                  {transcriptPdf ? (
                    <a
                      href={transcriptPdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-sm btn-primary w-50 btn-height mt-0 btn-width mobile-text-small h-100 d-flex justify-content-center align-items-center"
                    >
                      Transcript PDF
                    </a>
                  ) : (
                    <a className="w-54"></a>
                  )}
                  {transcriptSentimentPdf ? (
                    <a
                      href={transcriptSentimentPdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-sm btn-primary btn-height btn-width mt-0 mobile-text-small h-100 d-flex justify-content-center align-items-center"
                    >
                      Transcript PDF with sentiment
                    </a>
                  ) : (
                    <a className="w-54"></a>
                  )}
                </div>
              </div>
              <a
                onClick={scrollToQuestions}
                className="btn d-flex flex-center btn-sm btn-success questions-link btn-height"
              >
                <i className="ki-outline ki-down fs-1 icon-heading"></i>
                <h1 className="m-0 fw-light btn-sm fs-3 fw-bold">Questions</h1>
                <i className="ki-outline ki-down fs-1 ms-1 icon-heading"></i>
              </a>
            </div>
          </div>
          {transcriptExists && (
            <div
              className="resize-bar"
              ref={resizeBar}
              onMouseDown={handleMouseDown}
              onTouchStart={handleTouchStart}
            >
              <div ref={resizePill} className="pill-vertical">
                <div
                  ref={(el) => (resizePillLines.current[0] = el)}
                  className="line-vertical"
                ></div>
                <div
                  ref={(el) => (resizePillLines.current[1] = el)}
                  className="line-vertical"
                ></div>
              </div>
            </div>
          )}
          {windowWidth >= 576 && transcriptExists && (
            <div
              ref={transcriptWrapper}
              className="event-ended-height ms-5 mt-5 event-ended-initial-width mobile-display-none"
            >
              <TranscriptWrapper
                updateTags={updateTags}
                createNotification={createNotification}
                eventId={eventId}
                availableLanguages={availableLanguages}
                onFailedToFetchTranscript={() => setTranscriptExists(false)}
                hasTranscript={hasTranscript}
                width={windowWidth}
                onWordClick={seekTime}
                playerTime={playerTimeRef}
                seekedTime={seekedTime}
              ></TranscriptWrapper>
            </div>
          )}
          {transcriptExists && windowWidth <= 576 && (
            <a
              className="btn btn-primary transcript-modal-button fs-4 fw-bold mt-5"
              onClick={displayTranscript}
            >
              Display transcript
            </a>
          )}
          {windowWidth <= 576 && transcriptExists && (
            <EventTranscriptModal
              createNotification={createNotification}
              onFailedToFetchTranscript={() => setTranscriptExists(false)}
              seekedTime={seekedTime}
              width={windowWidth}
              availableLanguages={availableLanguages}
              hasTranscript={hasTranscript}
              eventId={eventId}
              isPlayerPlaying={videoPlaying}
              onPlay={setIsPlaying}
              onSeekTime={seekTime}
              videoLength={videoLength}
              company={company}
              title={eventTitle}
              playerTime={playerTimeRef}
              show={showTranscriptModal}
              handleClose={() => setShowTranscriptModal((prev) => !prev)}
            ></EventTranscriptModal>
          )}
        </>
      )}
    </div>
  );
};

export default EventEnded;
