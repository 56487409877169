import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useAuth } from "../../../../app/modules/auth";
import {
  getSession,
  getSessionWithKey,
  getUser,
  login,
  register,
  registerWithoutCSRF,
} from "../../../../app/modules/auth/core/_requests";
import { Modal } from "react-bootstrap";
import { PasswordMeterComponent } from "../../../assets/ts/components";
import { Color } from "../../notification/Notification";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";

const BASE_URL = import.meta.env.VITE_APP_BACKEND_URL;

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

interface RegisterModalProps {
  show: boolean;
  email: string;
  handleClose: () => void;
  createNewNotification: (color: Color, message: string) => void;
  openLoginModal: () => void;
  embedded?: boolean;
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First name is required"),
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  lastname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Last name is required"),
  password: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  changepassword: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password")], "Password and Confirm Password didn't match"),
  iAgree: Yup.bool().required("You must accept the terms and conditions"),
});

const RegisterModal: FC<RegisterModalProps> = ({
  show,
  handleClose,
  email,
  embedded,
  createNewNotification,
  openLoginModal
}) => {
  const [loading, setLoading] = useState(false);
  const { customBtnColor, customTextColor } = useThemeMode();
  const { setCurrentUser } = useAuth();

  useEffect(() => {
    formik.setFieldValue("email", email);
  }, [email]);

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    changepassword: "",
    subscribe: false,
    notifications: false,
    iAgree: false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      var response;
      try {
        if(embedded){
        response = await registerWithoutCSRF(
          values.email,
          values.firstname,
          values.lastname,
          values.password,
          values.changepassword,
          values.subscribe,
          values.notifications,
          values.iAgree
        );
        var { data } = await getSessionWithKey(
          embedded ? response.data.key : undefined
        );
      }else{
        response = await register(
          values.email,
          values.firstname,
          values.lastname,
          values.password,
          values.changepassword,
          values.subscribe,
          values.notifications,
          values.iAgree
        );
        var { data } = await getSession();
      }
        

        if (data.isAuthenticated) {
          if (embedded) {
            handleClose();
            createNewNotification(
              "success",
              "Your account has been registered, please sign up for the event."
            );
          } else {
            const url = new URL(window.location.href);
            url.searchParams.set("action", "sign_up");
            window.location.href = url.toString();
          }
        } else {
          handleClose();
          if(embedded){
            openLoginModal();
          }
          createNewNotification(
            "success",
            "Your account has been registered, please log in now."
          );
        }
      } catch (error: any) {
        setCurrentUser(undefined);
        if (error.response.status == 400) {
          if (error.response.data && error.response.data.password1) {
            formik.setFieldError("password", error.response.data.password1[0]);
          } else if (error.response.data && error.response.data.password2) {
            formik.setFieldError(
              "changepassword",
              error.response.data.password2[0]
            );
          } else if (error.response.data && error.response.data.email) {
            formik.setFieldError("email", error.response.data.email[0]);
          } else if (error.response.data && error.response.data.first_name) {
            formik.setFieldError(
              "firstname",
              error.response.data.first_name[0]
            );
          } else if (error.response.data && error.response.data.last_name) {
            formik.setFieldError("lastname", error.response.data.last_name[0]);
          } else if (error.response.data && error.response.data.iAgree) {
            formik.setFieldError("iAgree", error.response.data.iAgree[0]);
          }
        } else {
          setStatus("The registration details are incorrect");
        }
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const handleLinkedInRedirect = () => {
    window.location.href = `${BASE_URL}/account/oidc/linkedin_oidc/login/?next=app/fe${location.pathname}&action=sign_up`;
  };

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <Modal
      id="kt_modal_event_sign_up"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName={`modal-dialog modal-dialog-centered mw-400px ${embedded ? "mt-15 mw-600px" : ""}`}
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className="modal-header">
        <h3 className="my-0">{embedded ? "Create Stokk.io account" : "Create an account"}</h3>
      </div>
      <div className="modal-body">
        {!embedded && (
          <div className="d-grid">
            <a
              onClick={handleLinkedInRedirect}
              className="w-100 btn btn-linkedin"
            >
              <i className="fab fa-linkedin fs-1"></i>{" "}
              <span className="fw-bold">Sign up with LinkedIn</span>
            </a>
          </div>
        )}
        {!embedded && (
          <div className="separator separator-content border-secondary my-10">
            OR
          </div>
        )}
        <form
          className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          noValidate
          id="kt_login_signup_form"
          onSubmit={formik.handleSubmit}
        >
          {formik.status && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}

          {/* begin::Form group Firstname */}
          <div className="row mb-4">
            <div className="col">
              <input
                placeholder="First name"
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("firstname")}
                className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid":
                      formik.touched.firstname && formik.errors.firstname,
                  },
                  {
                    "is-valid":
                      formik.touched.firstname && !formik.errors.firstname,
                  }
                )}
              />
              {formik.touched.firstname && formik.errors.firstname && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.firstname}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
            <div className="col">
              {/* begin::Form group Lastname */}
              <input
                placeholder="Last name"
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("lastname")}
                className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid":
                      formik.touched.lastname && formik.errors.lastname,
                  },
                  {
                    "is-valid":
                      formik.touched.lastname && !formik.errors.lastname,
                  }
                )}
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.lastname}</span>
                  </div>
                </div>
              )}
              {/* end::Form group */}
            </div>
          </div>

          {/* begin::Form group Email */}
          <div className="fv-row mb-4">
            <input
              placeholder="Email"
              type="email"
              autoComplete="off"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control bg-transparent",
                { "is-invalid": formik.touched.email && formik.errors.email },
                {
                  "is-valid": formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Password */}
          <div className="fv-row mb-4" data-kt-password-meter="true">
            <div className="mb-1">
              <div className="position-relative mb-3">
                <input
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  {...formik.getFieldProps("password")}
                  className={clsx(
                    "form-control bg-transparent",
                    {
                      "is-invalid":
                        formik.touched.password && formik.errors.password,
                    },
                    {
                      "is-valid":
                        formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          <div className="fv-row mb-5">
            <input
              type="password"
              placeholder="Password confirmation"
              autoComplete="off"
              {...formik.getFieldProps("changepassword")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.changepassword &&
                    formik.errors.changepassword,
                },
                {
                  "is-valid":
                    formik.touched.changepassword &&
                    !formik.errors.changepassword,
                }
              )}
            />
            {formik.touched.changepassword && formik.errors.changepassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.changepassword}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className="fv-row mb-8 fv-plugins-icon-container">
            <label className="form-check form-check-inline">
              <input
                type="checkbox"
                {...formik.getFieldProps("notifications")}
                name="notifications"
                className="form-check-input"
                id="id_notifications"
              />
              <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">
                I agree to receive notification emails regarding my account and
                service updates
              </span>
            </label>
          </div>
          <div className="fv-row mb-8 fv-plugins-icon-container">
            <label className="form-check form-check-inline">
              <input
                type="checkbox"
                {...formik.getFieldProps("subscribe")}
                name="subscribe"
                className="form-check-input"
                id="id_subscribe"
              />
              <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">
                I would like to subscribe to newsletters and marketing emails
              </span>
            </label>
          </div>
          <div className="fv-row mb-8 fv-plugins-icon-container">
            <label className="form-check form-check-inline">
              <input
                type="checkbox"
                {...formik.getFieldProps("iAgree")}
                name="iAgree"
                className="form-check-input"
                required={true}
                id="id_iAgree"
              />
              <span className="form-check-label required fw-semibold text-gray-700 fs-base ms-1">
                I agree to the <a href="/terms-of-service/">Terms of Service</a>{" "}
                and <a href="/privacy-policy/">Privacy Policy</a>
              </span>
            </label>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className="text-center">
            <button
              type="submit"
              id="kt_sign_up_submit"
              className={`btn btn-lg w-100 mb-5 ${
                customBtnColor
                  ? "btn-iframe-custom " + customTextColor
                  : "btn-primary"
              } fs-4`}
              style={
                customBtnColor
                  ? { "--custom-btn-color": customBtnColor }
                  : {}
              }
              disabled={
                formik.isSubmitting || !formik.isValid || !formik.values.iAgree
              }
            >
              {!loading && <span className="indicator-label">Submit</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </Modal>
  );
};

export default RegisterModal;
