import { FC, ReactNode, useState } from "react";

interface DeletedQuestionWrapperProps {
  children: ReactNode;
  index: number;
}

const DeletedQuestionWrapper: FC<DeletedQuestionWrapperProps> = ({
  children,
  index,
}) => {
  const [openAccordion, setOpenAccordion] = useState<boolean>(false);
  const [openingAccordion, setOpeningAccordion] = useState<boolean>(false);

  return (
    <div className="accordion mt-5">
      <div
        className={`accordion-item ${
          openAccordion ? "open-radius-card" : "closed-radius"
        }`}
      >
        <h2 className="accordion-header">
          <button
            className={`accordion-button accordion-height fs-5 fw-medium ${
              openAccordion
                ? "text-primary open-radius"
                : "collapsed text-muted closed-radius"
            }`}
            type="button"
            onClick={() => setOpenAccordion(!openAccordion)}
          >
            Deleted question
            <i
              className={`ki-outline ki-eye-slash ms-2 fs-3 ${
                openAccordion ? "text-primary" : "text-muted"
              }`}
            ></i>
          </button>
        </h2>
        <div
          className={`accordion-collapse ${
            openAccordion ? `show` : "collapse"
          }`}
        >
          <div className="accordion-body-custom">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DeletedQuestionWrapper;
